import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/auth'

import Button from 'components/Button'

import box from 'assets/pngs/box.png'
import { NoOrdersContainer } from '../styles'

const NoOrders: React.FC = () => {
  const { user } = useAuth()
  const { push } = useHistory()
  const handleMakeOrder = () => {
    push('/')
  }
  return (
    <NoOrdersContainer>
      <img src={box} alt="caixa aberta" />

      <h3>Olá, {user.name.split(' ')[0]}</h3>
      <p>Ainda não há pedidos realizados</p>

      <Button color="secondary" onClick={handleMakeOrder}>
        Fazer pedido
      </Button>
    </NoOrdersContainer>
  )
}

export default NoOrders
