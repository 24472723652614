import styled from 'styled-components'
import { responsives } from 'styles/styleguide'

export const Container = styled.div`
  widht: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;

  @media (max-width: ${responsives.largeDevices}) {
    padding: 1rem 1rem 3.5rem;
  }
`
