import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Form } from '@unform/web'

import Voucher from 'assets/pngs/voucher.png'
import Balloon from 'assets/pngs/balloon.png'
import VoucherStep from 'assets/pngs/voucher-step.png'
import MaterialStep from 'assets/pngs/material-step.png'
import RegistrationStep from 'assets/pngs/registration-step.png'
import RequestStep from 'assets/pngs/request-step.png'
import NotebookGirlImg from 'assets/pngs/pexels-andrea-piacquadio.png'

import Button from 'components/Button'
import Input from 'components/Form/Input'
import CardJourney from 'components/CardJourney'
import PreIdentificationModal from 'components/PreIdentificationModal'
import Modal from 'components/Modal'
import Carousel from 'components/Carousel'

import { useAlert } from 'hooks/alert'
import { useOrder } from 'hooks/order'

import * as Yup from 'yup'

import { VoucherProps as ValidateVoucherProps } from 'interfaces'

import { Container } from './styles'

const voucherSchema = Yup.object().shape({
  code: Yup.string().required('Deve ser apresentado uma chave de acesso'),
})

const Home: React.FC = () => {
  const [openPreIdentification, setOpenPreIdentification] = useState(false)

  const { createModal } = useAlert()
  const { validateVoucher, voucher } = useOrder()

  const handleValidateVoucher = useCallback(
    async (voucherData: ValidateVoucherProps) => {
      try {
        await voucherSchema.validate(voucherData, {
          abortEarly: false,
        })

        await validateVoucher(voucherData)

        setOpenPreIdentification(true)
      } catch (err) {
        if (err instanceof Yup.ValidationError)
          createModal({
            title: 'Chave de acesso inválida!',
            description: 'Deve ser apresentado uma chave de acesso',
          })
        else if (err instanceof Error)
          createModal({
            title: err.name,
            description: err.message,
          })
        else console.error('err non prev', err)
      }
    },
    [voucher],
  )

  return (
    <Container>
      <div className="main">
        <Carousel />

        <div className="voucher box">
          <img src={Voucher} alt="livro" />
          <div>
            <p>
              Insira a chave de acesso que você recebeu para iniciar a compra
            </p>
            <Form onSubmit={handleValidateVoucher}>
              <Input
                name="code"
                type="text"
                placeholder="Digite aqui o número da chave de acesso"
              />

              <Button className="submit-code" type="submit" color="secondary">
                Começar
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <div className="journey">
        <h2 className="title">Como funciona?</h2>
        <p className="subtitle">
          Veja como adquirir seu material didático em 4 passos simples
        </p>
        <div className="steps">
          <CardJourney
            step={1}
            img={VoucherStep}
            title="Entre com a chave de acesso"
            description="Digite o número da chave de acesso no campo indicado para iniciar o processo"
          />
          <CardJourney
            step={2}
            img={MaterialStep}
            title="Confira seus materiais"
            description="Verifique informações como ano ou série do material antes de continuar, ok?"
          />
          <CardJourney
            step={3}
            img={RegistrationStep}
            title="Confirme o cadastro"
            description="Preencha os dados indicados e confirme seu cadastro para finalizar a compra"
          />
          <CardJourney
            step={4}
            img={RequestStep}
            title="Acompanhe seu pedido"
            description="Prontinho! Agora é só aguardar o pedido. Em breve, seu material didático estará em mãos"
          />
        </div>
      </div>

      <div className="help">
        <div className="box">
          <span />
          <h3 className="title">Ficou com dúvidas?</h3>

          <Link to="/help">
            <Button type="button">Acessar Ajuda</Button>
          </Link>

          <img src={Balloon} alt="balloon" />
        </div>
      </div>

      <Modal
        visible={openPreIdentification}
        img={NotebookGirlImg}
        onClose={() => setOpenPreIdentification(false)}>
        <PreIdentificationModal />
      </Modal>
    </Container>
  )
}

export default Home
