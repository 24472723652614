import styled from 'styled-components'
import { responsives, colors, sizes } from 'styles/styleguide'

export const UpdateContainer = styled.div`
  width: 100%;
  max-width: 1067px;

  > form {
    width: 100%;
    display: grid;
    place-items: center;

    padding: 1rem;
    margin-top: 2rem;

    border: 2px solid #cfd1ff;
    border-radius: 8px;

    > h2 {
      font-size: ${sizes.xl};
      color: ${colors.grayDark};
      margin: 1rem 0 1.5rem;

      justify-self: start;
    }

    > .radio_container {
      display: flex;
      justify-self: start;

      > div {
        > label + label {
          margin-left: 1rem;
        }
      }
    }

    > .main_form {
      width: 100%;
      display: flex;
      flex-flow: column;

      > .terms_container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        > div {
          flex-direction: row;
          align-items: center;
          margin-top: 1rem;
          gap: 0;

          > input {
            width: 24px;
            height: 24px;
          }

          > span {
            margin-left: 1rem;
            width: 100%;
            color: ${colors.grayDark};

            > a,
            button {
              background: transparent;
              color: ${colors.grayDark};

              text-decoration: underline;
              border: 0;

              cursor: pointer;
            }
          }
        }

        > .error {
          margin-top: 1rem;
          color: ${colors.error};
        }
      }
    }

    > button {
      width: 100%;
      max-width: 330px;
      margin: 2rem 0;
    }
  }

  > .loading {
    width: 3rem;
    height: 3rem;

    margin: 2rem auto;
    border: 6px solid ${colors.grayLight};
    border-top: 6px solid ${colors.primary};
    border-radius: 50%;

    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    > form {
      > .main_form {
        > .terms_container {
          width: calc(50% - 1rem);
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > form {
      > h2 {
        font-size: ${sizes.lg};
        margin: 0.5rem 0 1rem;
      }

      > .main_form {
        .terms_container {
          width: 100%;
          margin-top: 1rem;

          > div {
            display: flex;

            > span {
              font-size: ${sizes.sm};
              margin-left: 1rem;

              > button {
                font-size: ${sizes.sm};
              }
            }
          }

          > .error {
            font-size: ${sizes.sm};
          }
        }
      }

      > button {
        max-width: 100%;
      }
    }
  }
`

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  margin-top: 1.5rem;

  > span {
    display: flex;
    height: min-content;

    border-radius: 8px;
    background-color: ${colors.primaryLight};
    color: ${colors.primaryDark};
    font-weight: 500;

    padding: 1rem;

    &.span {
      grid-column: 3;
      background-color: transparent;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      width: max-content;
      margin-left: auto;
    }

    strong {
      font-size: ${sizes.base};
      color: ${colors.grayDark};
      font-weight: 500;

      display: block;
    }

    div {
      height: auto;
    }

    > .cep_info {
      display: flex;
      justify-content: flex-end;

      border: 0;
      background: transparent;

      color: ${colors.primaryDark};
      font-size: ${sizes.base};
      text-decoration: underline;
      cursor: pointer;
    }

    &.stateSignup {
      grid-row: 3;
      margin-top: -1rem;
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    grid-template-columns: repeat(2, 1fr);

    > span.span {
      grid-column: 2;
    }

    > div.stateSignup {
      grid-column: 2;
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    grid-template-columns: repeat(1, 1fr);

    > span.span {
      display: none;
    }

    > div.stateSignup {
      grid-row: 5;
      grid-column: 1;
    }
  }
`
