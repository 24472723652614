import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { useTerm } from 'hooks/term'

import Button from 'components/Button'
import LoginModal from 'components/LoginModal'
import logoImg from 'assets/icons/logo.png'

import { FiChevronUp } from 'react-icons/fi'

import { Container, BackgroundImage } from './styles'

const Footer: React.FC = () => {
  const { signOut, isAuthenticated } = useAuth()
  const [toggleSigninModal, setToggleSigninModal] = useState(false)

  const { handleToggleModalTerm, terms } = useTerm()

  const handleSignOut = useCallback(() => {
    signOut()
  }, [])

  const handleScrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const handleToggleSigninModal = () => {
    setToggleSigninModal((state) => !state)
  }

  return (
    <Container>
      <BackgroundImage />

      <div className="LogoContainer">
        <img src={logoImg} alt="logo_plug_book" />

        <span>
          <p>CNPJ: 42.092.470/0001-72</p>
          <p>
            Endereço: Avenida Raja Gabaglia,2720 Subsolo 1/Sala LE. - Estoril.
            Belo Horizonte/MG.
          </p>
          <p> CEP: 30494-170</p>
          Copyright © 2021 Bernoulli - Desenvolvido por JustDigital - Versão
          1.0. - 19/08/2021
        </span>
      </div>

      <div className="LinksContainer">
        <div>
          <h1>O Site</h1>
          <Link to="/" onClick={handleScrollToTop}>
            Início
          </Link>
          <Link to="/about" onClick={handleScrollToTop}>
            Sobre
          </Link>
          <Link to="/help" onClick={handleScrollToTop}>
            Ajuda
          </Link>
        </div>

        {isAuthenticated() && (
          <div>
            <h1>Login</h1>

            <>
              <Link to="/profile">Meu perfil</Link>
              <Link to="/orders">Meus pedidos</Link>
              <button type="button" onClick={handleSignOut}>
                Sair
              </button>
            </>
          </div>
        )}

        <div>
          <h1>Legal</h1>

          {terms.map((term) => (
            <button
              key={term.id}
              type="button"
              onClick={() => handleToggleModalTerm(term)}>
              {term.title}
            </button>
          ))}
        </div>
      </div>

      <Button color="secondary" onClick={handleScrollToTop}>
        <FiChevronUp size={48} />
      </Button>

      <LoginModal
        visible={toggleSigninModal}
        onClose={handleToggleSigninModal}
      />
    </Container>
  )
}

export default Footer
