import styled from 'styled-components'
import { colors, sizes, responsives } from 'styles/styleguide'

import Bg from 'assets/pngs/help-bg.png'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 5rem);
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 3.5rem 1rem;
  padding-bottom: 200px;

  > * {
    max-width: 905px;
  }

  > h2 {
    font-size: ${sizes.h1};
    font-weight: 700;
    color: ${colors.grayDark};
    margin-bottom: 3.5rem;
  }

  > p {
    color: ${colors.grayDark};
    text-align: justify;
    line-height: 150%;
    margin-bottom: 2rem;
    + p {
      margin-bottom: 3.5rem;
    }
  }
  > .img-container {
    width: 905px;
    max-width: 95vw;
    text-align: right;
    > img {
      max-width: 100%;
      margin-right: -70px;
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    > .img-container {
      text-align: center;
      > img {
        margin-right: 0;
      }
    }
  }
`

export const Background = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  width: 100%;
  max-width: 1366px;
  height: 200px;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: bottom -100px right -600px;
  @media (max-width: ${responsives.largeDevices}) {
    background-position: bottom -120px right -600px;
  }
`
