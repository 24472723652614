import * as Yup from 'yup'

interface Errors {
  [key: string]: string
}

// Yup error
export const getValidationErrors = (err: Yup.ValidationError): Errors => {
  const validationErrors: Errors = {}

  err.inner.forEach((error) => {
    if (error.path) {
      validationErrors[error.path] = error.message
    }
  })

  return validationErrors
}

export const validationErrorsArray = (...errors: string[]): boolean => {
  return errors.filter((error) => error !== undefined).length > 0
}

// Validate CPF according the Receita Federal
export const cpfValidation = (cpf: string): boolean => {
  let sum = 0
  let rest
  const strCPF = cpf.replaceAll('.', '').replaceAll('-', '')
  if (
    strCPF.length !== 11 ||
    !Array.from(strCPF).filter((e) => e !== cpf[0]).length
  ) {
    return false
  }

  for (let i = 1; i <= 9; i += 1)
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i)
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(9, 10), 10)) return false

  sum = 0
  for (let i = 1; i <= 10; i += 1)
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i)
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(10, 11), 10)) return false

  return true
}
