import React, { useCallback } from 'react'

import Input from 'components/Form/Input'
import Select from 'components/Form/Select'

import { maskTelephone } from 'utils/mask'

import { FormHandles } from '@unform/core'
import { FormContent } from '../styles'

interface PersonFormProps {
  formRef: React.RefObject<FormHandles>
}

const Company: React.FC<PersonFormProps> = ({ formRef }: PersonFormProps) => {
  const handleTelephoneMask = useCallback((data) => {
    const { value } = data.target

    formRef.current?.setFieldValue('phone', maskTelephone(value))
  }, [])

  return (
    <FormContent>
      <div>
        <strong>Nome do responsável:</strong>
        <Input name="name" disabled />
      </div>

      <div>
        <strong>CNPJ:</strong>
        <Input name="cnpj" mask="99.999.999/9999-999" disabled />
      </div>

      <div>
        <strong>Razão Social:</strong>
        <Input name="companyName" disabled />
      </div>

      <div>
        <strong>Inscrição Estadual:</strong>
        <Select name="hasStateSignup" defaultValue="Não" disabled>
          <option key={1} value="Sim">
            Sim
          </option>

          <option key={2} value="Não">
            Não
          </option>
        </Select>
      </div>

      <div className="stateSignup">
        <Input name="stateSignup" disabled />
      </div>

      <div>
        <strong>Inscrição Municipal:</strong>
        <Input name="citySignup" disabled />
      </div>

      <div>
        <strong>Telefone:</strong>
        <Input name="phone" onChange={handleTelephoneMask} />
      </div>
    </FormContent>
  )
}

export default Company
