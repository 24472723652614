import React, { createContext, useCallback, useContext, useState } from 'react'

import AlertContainer from 'components/Alert'

interface AlertContextData {
  createModal(message: AlertMessage): void
  closeModal(): void
}

interface AlertMessage {
  title: string
  description?: string
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData)

export const AlertProvider: React.FC = ({ children }) => {
  const [isShowing, setIsShowing] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const createModal = useCallback((message: AlertMessage) => {
    if (message.title && message.description) {
      setTitle(message.title)
      setDescription(message.description)
    }

    setIsShowing((state) => !state)
  }, [])

  const closeModal = useCallback(() => {
    setTitle('')
    setTitle('')
    setIsShowing(false)
  }, [])

  return (
    <AlertContext.Provider value={{ createModal, closeModal }}>
      {children}

      {isShowing && <AlertContainer title={title} description={description} />}
    </AlertContext.Provider>
  )
}

export const useAlert = (): AlertContextData => {
  const context = useContext(AlertContext)

  if (!context) throw new Error('useAlert must be within AlertProvider')

  return context
}
