import React from 'react'
import { sanitize } from 'dompurify'

import { FaqCategoryProps } from 'screens/Help'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Container } from './styles'

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  faqs: FaqCategoryProps[]
  open: boolean
  setOpen: () => void
}

const ItemAccordion: React.FC<ItemProps> = ({
  name,
  faqs,
  open,
  setOpen,
  ...rest
}) => {
  const faqsPanel = () => {
    return faqs.map(({ id, question, answer }: FaqCategoryProps) => {
      return (
        <React.Fragment key={question}>
          <p className="question">{question}</p>

          {/* eslint-disable react/no-danger */}
          <div
            className="answer"
            key={id}
            dangerouslySetInnerHTML={{ __html: sanitize(answer) }}
          />
        </React.Fragment>
      )
    })
  }
  return (
    <Container {...rest}>
      <button
        className={`label ${open ? 'active' : ''}`}
        type="button"
        onClick={setOpen}>
        <p>{name}</p>

        {open ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
      </button>

      <div className={`panel ${open ? 'active' : ''}`}>{faqsPanel()}</div>
    </Container>
  )
}

export default ItemAccordion
