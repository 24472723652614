import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import _ from 'lodash'

import api from 'services/api'

import { throwError } from 'utils/throwError'

import {
  PreIdentificationProps,
  SchoolProps,
  SegmentProps,
  SerieProps,
  VoucherProps,
} from 'interfaces'

import { useStorage } from './storage'

interface ProductProps {
  description: string
  name: string
  value: number
  codeProduct: string
}

export interface KitsProps {
  id: number
  name: string
  value: number
  areaType: number
  products: ProductProps[]
}

export interface CollectionProps {
  id: number
  name: string
  value: number
  kits: KitsProps[]
}

interface OrderContextData {
  voucher: VoucherProps
  validateVoucher: (voucherData: VoucherProps) => Promise<void>
  school: SchoolProps
  preIdentification: PreIdentificationProps
  setPreIdentification: (identification: PreIdentificationProps) => void
  principalCollection: CollectionProps
  itineraryCollection: CollectionProps
  extraCollection: CollectionProps
  cart: KitsProps[]
  toggleKitOnCart(kit: KitsProps, collectionType: number): Promise<void>
  segment: SegmentProps
  serie: SerieProps
  cartTotalValue: number
  orderTotalValue: number
  shippingType: 'em casa' | 'na escola'
  campaign: string
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData)

export const OrderProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const cartStoraged = sessionStorage.getItem('cart')
    if (cartStoraged) return JSON.parse(cartStoraged) as KitsProps[]
    return [] as KitsProps[]
  })

  const [voucher, setVoucher] = useStorage('voucher', {} as VoucherProps)
  const [preIdentification, setStatePreIdentification] = useStorage(
    'preIdentification',
    {} as PreIdentificationProps,
  )
  const [campaign, setCampaign] = useState('')

  const school = useMemo(() => {
    if (_.isEmpty(voucher)) return {} as SchoolProps
    return voucher.voucherGroup.school
  }, [voucher])

  const segment = useMemo(() => {
    if (_.isEmpty(voucher)) return {} as SegmentProps
    return voucher.voucherGroup.segment
  }, [voucher])

  const serie = useMemo(() => {
    if (_.isEmpty(voucher)) return {} as SerieProps

    if (segment.id === 5) voucher.voucherGroup.serie.name = ''

    return voucher.voucherGroup.serie
  }, [voucher, segment])

  const shippingType = useMemo(() => {
    if (
      school.deliveryType === '003' &&
      new Date(school.shippingLimitDate) < new Date()
    )
      return 'em casa'

    return 'na escola'
  }, [school])

  const [principalCollection, setPrincipalCollection] =
    useState<CollectionProps>({} as CollectionProps)

  const [itineraryCollection, setItineraryCollection] =
    useState<CollectionProps>({} as CollectionProps)

  const [extraCollection, setExtraCollection] = useState<CollectionProps>(
    {} as CollectionProps,
  )

  const setPreIdentification = (identification: PreIdentificationProps) => {
    setStatePreIdentification(identification)
  }

  const availableVoucher = useCallback(
    async (foundVoucherData: VoucherProps) => {
      await api.put(`/api/vouchers/${foundVoucherData.id}`, {
        status: 'Em uso',
      })

      const newVoucher: VoucherProps = {
        id: foundVoucherData.id,
        code: foundVoucherData.code,
        updateAt: new Date().getTime(),
        voucherGroup: foundVoucherData.voucherGroup,
      }

      setCart([] as KitsProps[])
      sessionStorage.removeItem('cart')

      setVoucher(newVoucher)
      setStatePreIdentification({} as PreIdentificationProps)
    },
    [],
  )

  const inUseVoucher = useCallback(
    (foundVoucherData: VoucherProps) => {
      const maxTime = 10
      const timeUsed = (new Date().getTime() - voucher.updateAt) / 60 / 1000

      if (timeUsed > maxTime || voucher.id !== foundVoucherData.id)
        throwError('Chave de acesso em uso!', 'Tente novamente em 10 minutos')
    },
    [voucher],
  )

  const validateVoucher = useCallback(
    async (voucherData: VoucherProps) => {
      const {
        data: [foundVoucherData],
      } = await api.get('/api/vouchers', {
        params: {
          code: voucherData.code.trim(),
        },
      })

      if (foundVoucherData) {
        const { status } = foundVoucherData

        if (status === 'Disponível') {
          availableVoucher(foundVoucherData)
        } else if (status === 'Em uso') {
          inUseVoucher(foundVoucherData)
          // throwError('Chave de acesso em uso!', 'Tente novamente em 10 minutos')
        } else {
          throwError(
            'Chave de acesso invalida!',
            'Entre em contato com a sua escola',
          )
        }
      } else {
        throwError(
          'Chave de acesso invalida!',
          `Não foi possível encontrar a chave de acesso "${voucherData.code}"`,
        )
      }
    },
    [voucher],
  )

  const toggleKitOnCart = useCallback(
    async (kit, areaType) => {
      const foundInCart = cart.findIndex((kitOnCart) => kitOnCart.id === kit.id)
      let cartToSave = cart

      switch (areaType) {
        case 1:
          return
        case 2:
          if (foundInCart >= 0) {
            cartToSave = cartToSave.filter((product) => product.id !== kit.id)
          } else {
            const filteredCartByCollectionType = cartToSave.filter(
              (kitOnCart) => kitOnCart.areaType !== 2,
            )

            cartToSave = [...filteredCartByCollectionType, kit]
          }

          break
        default:
          if (foundInCart >= 0) {
            cartToSave = cartToSave.filter((product) => product.id !== kit.id)
          } else {
            cartToSave = [...cartToSave, kit]
          }

          break
      }

      setCart(cartToSave)
      sessionStorage.setItem('cart', JSON.stringify(cartToSave))
    },
    [cart],
  )

  const getPriceCollection = useCallback(
    (id: number, areaType: number) => {
      switch (areaType) {
        case 1:
          if (_.isEmpty(principalCollection)) return 0
          return principalCollection.kits[0].value

        case 2:
          if (_.isEmpty(itineraryCollection)) return 0
          return itineraryCollection?.kits.filter((kit) => kit.id === id)[0]
            .value

        default:
          if (_.isEmpty(extraCollection) || !areaType) return 0
          return extraCollection?.kits.filter((kit) => kit.id === id)[0].value
      }
    },
    [principalCollection, itineraryCollection, extraCollection],
  )

  const cartTotalValue = useMemo(() => {
    const totalValue = cart.reduce((accumulator, current): number => {
      return accumulator + getPriceCollection(current.id, current.areaType)
    }, 0)

    return totalValue
  }, [cart, getPriceCollection])

  const orderTotalValue = useMemo(() => {
    return cartTotalValue + school.shippingPrice
  }, [cartTotalValue, school])

  useEffect(() => {
    async function loadCollections() {
      if (!_.isEmpty(preIdentification)) {
        const params = {
          school: preIdentification?.school,
          segment: preIdentification?.segment,
          serie: preIdentification?.serie,
          isFront: 1,
          'campaign.isActual': 1,
        }

        setPrincipalCollection({} as CollectionProps)
        setItineraryCollection({} as CollectionProps)
        setExtraCollection({} as CollectionProps)

        try {
          const {
            data: [kitData],
          } = await api.get(`/api/kits`, {
            params,
          })

          if (kitData) {
            setCampaign(kitData.campaign)

            kitData.collections.map((collection: CollectionProps) => {
              switch (collection.name) {
                case 'Principal':
                  setPrincipalCollection({
                    ...collection,
                    kits: [{ ...collection.kits[0], areaType: collection.id }],
                  })
                  setCart([
                    {
                      ...collection.kits[0],
                      areaType: collection.id,
                    },
                  ])

                  if (!sessionStorage.getItem('cart'))
                    sessionStorage.setItem(
                      'cart',
                      JSON.stringify([
                        {
                          ...collection.kits[0],
                          areaType: collection.id,
                        },
                      ]),
                    )

                  break
                case 'Itinerário':
                  setItineraryCollection({
                    ...collection,
                    kits: collection.kits.map((kit) => ({
                      ...kit,
                      areaType: collection.id,
                    })),
                  })
                  break
                default:
                  setExtraCollection((state) => {
                    if (_.isEmpty(state))
                      return {
                        ...collection,
                        kits: collection.kits.map((kit) => ({
                          ...kit,
                          areaType: collection.id,
                        })),
                      }

                    return {
                      ...state,
                      kits: [
                        ...state.kits,
                        ...collection.kits.map((kit) => ({
                          ...kit,
                          areaType: collection.id,
                        })),
                      ],
                    }
                  })
                  break
              }
            })
          }

          const cartStoraged = sessionStorage.getItem('cart')
          if (cartStoraged) setCart(JSON.parse(cartStoraged))
        } catch {
          console.log()
        }
      }
    }

    loadCollections()
  }, [preIdentification])

  return (
    <OrderContext.Provider
      value={{
        voucher,
        validateVoucher,
        preIdentification,
        setPreIdentification,
        school,
        principalCollection,
        itineraryCollection,
        extraCollection,
        segment,
        serie,
        cart,
        cartTotalValue,
        orderTotalValue,
        toggleKitOnCart,
        shippingType,
        campaign,
      }}>
      {children}
    </OrderContext.Provider>
  )
}

export const useOrder = (): OrderContextData => {
  const context = useContext(OrderContext)

  if (!context) throw new Error('useOrder must be within OrderProvider')

  return context
}
