import React, { useCallback } from 'react'

import Input from 'components/Form/Input'

import { maskTelephone } from 'utils/mask'

import { FormHandles } from '@unform/core'
import { FormContainer } from '../styles'

interface PersonFormProps {
  formRef: React.RefObject<FormHandles>
}

const Person: React.FC<PersonFormProps> = ({ formRef }) => {
  const handleTelephoneMask = useCallback((data) => {
    const { value } = data.target

    formRef.current?.setFieldValue('phone', maskTelephone(value))
  }, [])

  return (
    <FormContainer>
      <strong>CPF:</strong>
      <Input name="cpf" mask="999.999.999-99" placeholder="000.000.000-00" />

      <strong>Nome do responsável:</strong>
      <Input name="name" placeholder="Nome que constará na Nota Fiscal" />

      <strong>RG:</strong>
      <Input name="rg" mask="99.999.999-*" placeholder="00.000.000-0" />

      <strong>Órgão Expedidor/UF:</strong>
      <Input name="uf" placeholder="XXX" />

      <strong>Data de nascimento:</strong>
      <Input name="birthDate" mask="99/99/9999" placeholder="dd/mm/aaaa" />

      <strong>Telefone:</strong>
      <Input
        name="phone"
        onChange={handleTelephoneMask}
        placeholder="(00) 0000 0000"
      />

      <strong>E-mail:</strong>
      <Input name="email" placeholder="exemplo@exemplo.com.br" />

      <strong>Confirme seu e-mail:</strong>
      <Input name="emailConfirmation" placeholder="exemplo@exemplo.com.br" />

      <strong>Senha:</strong>
      <Input
        name="password"
        type="password"
        placeholder="********"
        isPassword
      />
      <p>
        *A senha deve conter 8 ou mais caracteres com uma combinação de letras,
        números e símbolos
      </p>

      <strong>Confirme sua senha:</strong>
      <Input
        name="passwordConfirmation"
        type="password"
        placeholder="********"
        isPassword
      />
    </FormContainer>
  )
}

export default Person
