import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { FiCheck } from 'react-icons/fi'

import { Container, Step } from './styles'

interface StepsProps {
  current: number
}

const Steps: React.FC<StepsProps> = ({ current }: StepsProps) => {
  const { push } = useHistory()
  const steps = ['Materiais', 'Identificação', 'Revisão', 'Pagamento']

  const handleNavigate = useCallback((currentStep) => {
    switch (currentStep) {
      case 1:
        push('materials')
        break
      case 2:
        push('identification')
        break
      case 3:
        push('revision')
        break
      case 4:
        push('payment')
        break
      default:
    }
  }, [])

  return (
    <Container>
      {steps.map((step, i) => {
        const index = i + 1
        return (
          <Step key={step} complete={index <= current}>
            <button
              onClick={() => handleNavigate(index)}
              disabled={index > current}
              type="button"
              className="circle">
              {index <= current - 1 ? <FiCheck /> : i + 1}
            </button>
            {index > 1 && <div className="line" />}
            <span>{step}</span>
          </Step>
        )
      })}
    </Container>
  )
}

export default Steps
