import React from 'react'
import { Link } from 'react-router-dom'

import Page404Img from 'assets/pngs/page-404.png'

import Button from 'components/Button'

import { Container, Content, Background } from './styles'

const Page404: React.FC = () => {
  return (
    <Container>
      <Content>
        <img src={Page404Img} alt="mulher com balão de fala" />
        <h1>Página não Encontrada!</h1>
        <p>
          Parece que a página que você está tentando <br /> acessar não existe
          mais.
        </p>
        <Link to="/">
          <Button type="button" color="secondary">
            RETORNAR
          </Button>
        </Link>
      </Content>
      <Background />
    </Container>
  )
}

export default Page404
