// Main colors of the app
export const colors = {
  primary: '#8387F7',
  primaryDark: '#4105D3',
  primaryLight: '#CFD1FF',
  secondary: '#2EC3E5',
  secondaryDark: '#1F8299',
  secondaryLight: '#99ECFF',
  tertiary: '#FDB515',
  tertiaryDark: '#805702',
  tertiaryLight: '#FFE6B2',
  success: '#1FDF49',
  error: '#B00020',
  gray: '#9B9B96',
  grayDark: '#52524E',
  grayLight: '#CCCCC7',
}

// Font sizes
export const sizes = {
  xs: '0.6rem',
  sm: '0.8rem',
  base: '1rem',
  lg: '1.2rem',
  xl: '1.4rem',
  h3: '1.6rem',
  h2: '1.8rem',
  h1: '2.2rem',
}

// Responsive sizes for simple use
export const responsives = {
  largeDevices: '1024px',
  mediumDevices: '720px',
  smallDevices: '320px',
}
