import styled from 'styled-components'
import { colors } from 'styles/styleguide'

export const Container = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }

  .label {
    text-decoration: none;
    font-weight: 500;
    padding: 0.8rem;
    color: ${colors.grayDark};
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid ${colors.grayLight};
    border-radius: 4px;
    cursor: pointer;

    > svg {
      stroke: ${colors.primaryDark};
    }

    &:hover,
    &.active {
      background: ${colors.primary};
      color: #fff;

      > svg {
        stroke: #fff;
      }
    }
  }

  .panel {
    position: relative;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 0s, opacity 0.5s ease-out;

    background-color: #fafaf7;
    border-radius: 0 0 4px 4px;

    .question {
      color: ${colors.grayDark};
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .answer {
      margin-bottom: 1rem;
      color: #81817d;

      a {
        text-decoration: none;
        color: inherit;
        font-weight: 500;

        &:hover {
          font-weight: 700;
        }
      }

      img {
        width: 100%;
        max-width: 400px;
      }
    }

    &.active {
      visibility: visible;
      opacity: 1;
      height: auto;
      padding: 2rem 1.8rem 1rem;
    }
  }
`
