import styled from 'styled-components'

export const NavbarElements = styled.nav`
  width: 100%;
  max-width: 700px;

  display: flex;
  flex: row wrap;
  justify-content: space-around;

  margin: 0 auto;
  z-index: 2;

  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;

    &:hover,
    &.active {
      border-bottom: 2px solid #2ec3e5;
      margin-bottom: -2px;
    }
  }
`
