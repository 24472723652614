import styled, { css } from 'styled-components'
import { colors } from 'styles/styleguide'

interface ContainerProps {
  hasError?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;

  border: 2px solid ${colors.grayLight};
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;

  background: transparent;
  color: ${colors.grayLight};

  display: flex;
  align-items: center;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 2px solid ${colors.error};
    `}

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: ${colors.grayDark};

    &::placeholder {
      color: ${colors.gray};
    }

    &:disabled {
      color: ${colors.gray};
    }
  }

  svg {
    cursor: pointer;
  }
`
