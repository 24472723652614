import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

import api from 'services/api'
import TermsModal from 'components/TermsModal'

import { useAlert } from './alert'
import { useStorage } from './storage'

interface TermsPayload {
  [key: number]: string
}

export interface TokenPayload {
  id: number
  email: string
  name: string
  // eslint-disable-next-line camelcase
  required_terms?: TermsPayload
}

interface User {
  id: number
  email: string
  name: string
}

interface AuthParams {
  email: string
  password: string
}

export interface AddressProps {
  id: number
  postalCode: string
  street: string
  city: string
  state: string
  neighborhood: string
  number: string
  complement: string
}

interface TermProps {
  term: { title: string }
}

interface UserData {
  id: number
  email: string
  name: string
  documentType: 'CPF' | 'CNPJ'
  birthDate: string
  cpf: string
  rg: string
  uf: string
  cnpj: string
  stateRegistration: string
  municipalRegistration: string
  companyName: string
  phone: string
  addresses: AddressProps[]
  termAccepteds: TermProps[]
  orders: string[]
}

interface AuthContextData {
  user: User
  token: string
  userData: UserData
  signIn(params: AuthParams): Promise<void>
  signOut(): void
  resetPassword(email: string): Promise<void>
  isAuthenticated(): boolean
  getUserData(): Promise<void>
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useStorage('token', '')
  const [userData, setUserData] = useState<UserData>({} as UserData)
  const [toggleTermsModal, setToggleTermsModal] = useState(false)

  const { createModal } = useAlert()

  const user = useMemo(() => {
    if (token === '') return {} as User
    const tokenDecoded = jwt.decode(token) as TokenPayload
    console.log(tokenDecoded)
    return {
      id: tokenDecoded.id,
      email: tokenDecoded.email,
      name: tokenDecoded.name,
    } as User
  }, [token])

  const requiredTerms = useMemo(() => {
    if (token === '') return null
    const termsUpdated = sessionStorage.getItem('termsUpdated')
    const tokenDecoded = jwt.decode(token) as TokenPayload

    if (tokenDecoded?.required_terms && !termsUpdated) {
      const terms = Object.entries(tokenDecoded.required_terms)

      const termsToUpdate = terms.map(([id, status]): { id: string } | null => {
        if (status === 'NOT_ACCEPTED') {
          setToggleTermsModal(true)

          return {
            id,
          }
        }

        return null
      }) as { id: string }[]

      return termsToUpdate
    }

    return null
  }, [token])

  const signIn = useCallback(async (authParams: AuthParams) => {
    const response = await api.post('/login', authParams)

    if (response.status === 200) {
      setToken(response.data.token)

      sessionStorage.setItem('token', response.data.token)
      sessionStorage.removeItem('termsUpdated')
    } else {
      throw new Error()
    }
  }, [])

  const signOut = useCallback(async () => {
    setToken('')
    setUserData({} as UserData)

    sessionStorage.removeItem('token')
  }, [])

  const resetPassword = useCallback(async (email: string) => {
    await api.post('/api/reset-password', { email })
  }, [])

  const getUserData = useCallback(async () => {
    if (_.isEmpty(userData)) {
      try {
        const { data } = await api.get(`/api/users/${user.id}`)
        if (data) {
          setUserData(data)
        }
      } catch (err) {
        setUserData({} as UserData)
      }
    }
  }, [user.id])

  const isAuthenticated = useCallback(() => {
    return !_.isEmpty(user)
  }, [user])

  const handleToggleTermsModal = useCallback(() => {
    sessionStorage.setItem('termsUpdated', JSON.stringify(true))
    setToggleTermsModal((state) => !state)

    document.location.reload()
  }, [])

  useEffect(() => {
    async function verifyToken() {
      const tokenDecoded = jwt.decode(token) as {
        exp: number
      }

      if (tokenDecoded?.exp && Date.now() >= tokenDecoded?.exp * 1000) {
        createModal({
          title: 'Sua sessão expirou',
          description: 'Você será desconectado.',
        })

        await signOut()
      }
    }

    verifyToken()
  }, [token, user])

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        userData,
        signIn,
        signOut,
        resetPassword,
        isAuthenticated,
        getUserData,
      }}>
      {children}

      {!!toggleTermsModal && (
        <TermsModal
          visible={!!toggleTermsModal}
          handleOnClose={handleToggleTermsModal}
          requiredTerms={requiredTerms}
        />
      )}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('useAuth must be within AuthProvider')

  return context
}
