import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fffffe.png'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 5rem);

  padding: 3rem 0;

  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-position: top -840px right -580px;
  background-repeat: no-repeat;

  > .loading {
    width: 3rem;
    height: 3rem;

    margin: 4rem auto;
    border: 6px solid ${colors.grayLight};
    border-top: 6px solid ${colors.primary};
    border-radius: 50%;

    animation: spin 1.5s linear infinite;
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 1rem 0 4rem;
  }
`

export const PaymentContent = styled.div`
  max-width: 1067px;
  width: 90%;

  padding: 2rem 4rem;
  margin-top: 2rem;

  background: #fff;

  border: 1px solid rgba(207, 209, 255, 1);
  border-radius: 4px;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.25);

  > h1 {
    font-weight: 700;
    font-size: ${sizes.lg};
    color: ${colors.grayDark};
  }

  > div {
  }

  .total {
    background-color: ${colors.primaryLight};
    margin-bottom: 2rem;

    > p {
      color: #000;
    }

    > strong {
      color: ${colors.primaryDark};
    }
  }

  .paymentMethod {
    width: 100%;

    .paymentMethodContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      padding: 0;

      > button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 1rem 0 0;
        padding: 1rem;

        background: transparent;
        border: 1px solid rgba(207, 209, 255, 1);
        border-radius: 4px;
        cursor: pointer;

        > span {
          min-height: 46px;
          display: flex;
          flex-flow: column;
          justify-content: center;

          font-size: ${sizes.base};
          color: ${colors.grayDark};
          font-weight: 700;

          > p {
            font-weight: 400;
            margin-top: 0.5rem;
          }
        }

        > input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          width: 20px;
          height: 20px;
          margin-right: 0.4rem;

          border-radius: 50%;
          border: 2px solid ${colors.secondary};

          cursor: pointer;

          &:checked {
            &::before {
              content: '';
              display: flex;

              width: 10px;
              height: 10px;

              margin-top: 3px;
              margin-left: 3px;

              border-radius: 50%;
              background: ${colors.secondary};
            }
          }
        }
      }
    }

    .cardForm {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;

      .cardFormRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4rem;

        > div {
          display: flex;
          flex-flow: column;

          > strong {
            font-size: ${sizes.base};
            font-weight: 500;
            color: ${colors.grayDark};
          }

          > div {
            height: auto;

            display: flex;
            margin-top: 0.25rem;

            > input {
              width: 100%;
            }
          }
        }
      }

      > div:first-child {
        width: 90%;
        grid-column: span 2;
      }

      > div {
        width: 80%;
        display: flex;
        flex-flow: column;
        margin-top: 1rem;

        > strong {
          font-size: ${sizes.base};
          font-weight: 500;
          color: ${colors.grayDark};
        }

        > div {
          height: auto;

          display: flex;
          margin-top: 0.25rem;
        }
      }
    }

    .submitButton {
      position: relative;
      width: 330px;
      margin: 5rem auto 0;
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    .paymentMethod {
      .cardForm {
        .cardFormRow {
          column-gap: 1rem;
        }

        > div,
        > div:first-child {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    width: 100%;

    margin: 0;
    padding: 1rem;

    border: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);

    > h1 {
      font-size: ${sizes.base};
      margin-bottom: 1rem;
    }

    .total {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      > p {
        color: ${colors.grayDark};
      }
    }

    .paymentMethod {
      .paymentMethodContainer {
        grid-template-columns: 1fr;
        gap: 1rem;

        button {
          margin: 0;
        }
      }

      .cardForm {
        display: flex;
        flex-flow: column;

        .cardFormRow {
          > div {
            max-width: 10rem;
          }
        }

        div {
          div {
            width: 100%;
          }
        }
      }

      > .submitButton {
        width: 100%;
      }
    }
  }
`

export const Kit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(207, 209, 255, 1);
  gap: 1rem;

  margin: 1rem 0;
  padding: 1rem 2rem;
  border-radius: 4px;

  > span,
  p {
    font-size: ${sizes.base};
    color: ${colors.grayDark};

    > strong {
      font-weight: 500;
    }
  }

  > strong {
    font-size: ${sizes.lg};
    font-weight: 700;
    color: ${colors.grayDark};
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem;
    margin: 0;

    border: 2px solid ${colors.primaryLight};
    border-bottom: 0px;

    border-radius: 0;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;

    > span {
      display: flex;
      flex-flow: column-reverse;
    }

    > strong {
      margin-left: 2rem;
      color: ${colors.primaryDark};
    }

    & + div {
      border-radius: 0px;
    }
  }
`
