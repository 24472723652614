import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import * as Yup from 'yup'
import * as valid from 'card-validator'

import axios from 'axios'
import api from 'services/api'
import { useAlert } from 'hooks/alert'
import { useAuth } from 'hooks/auth'
import { useOrder, KitsProps } from 'hooks/order'

import Steps from 'components/Steps'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import Button from 'components/Button'

import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import { getValidationErrors, cpfValidation } from 'utils/validation'
import { formatValue } from 'utils/formatValue'
import { throwError } from 'utils/throwError'

import { Container, Kit, PaymentContent } from './styles'

type PaymentMethods = 'credit_card' | 'boleto'
type CardMask = '9999 9999 9999 9999 9999' | '9999 999999 999999'

interface CreditCardForm {
  number: string
  expirationDate: string
  cvv: number
  holderName: string
  installments: number

  cpf: string

  paymentMethod: PaymentMethods
}

interface OrderCreditCard {
  number: string
  expMonth: string
  expYear: string
  cvv: number
  flag?: string
}

interface OrderProduct {
  price: number
  codeProduct: string
  name: string
  description: string
}

interface OrderArea {
  price: number
  area: string
  orderProducts: OrderProduct[]
}

interface OrderAreaType {
  price: number
  areaType: string
  orderAreas: OrderArea[]
}

interface OrderPayload {
  customer: string
  student: {
    name: string
    guardian: string
  }
  installments: number
  amount: number
  paymentMethod: PaymentMethods
  voucher: string
  price: number
  campaign: string
  school: string
  segment: string
  serie: string
  postalCode: string
  street: string
  city: string
  state: string
  neighborhood: string
  number: string
  complement: string
  orderAreaTypes: OrderAreaType[]
  creditCard: OrderCreditCard
}

const cardFlags = [
  'visa',
  'mastercard',
  'american-express',
  'diners-club',
  'discover',
  'jcb',
  'maestro',
  'elo',
  'hiper',
  'hipercard',
]

const Payment: React.FC = () => {
  const { push } = useHistory()
  const { createModal } = useAlert()
  const { user, userData, getUserData } = useAuth()
  const {
    cart,
    orderTotalValue,
    voucher,
    school,
    segment,
    serie,
    preIdentification,
    shippingType,
    campaign,
  } = useOrder()

  const formRef = useRef<FormHandles>(null)
  const [isLoading, setIsLoading] = useState(false)
  const paymentMethodRefs = useRef<HTMLInputElement[]>([])
  const paymentMethods = [
    { type: 'boleto', label: 'Boleto', detail: '' },
    {
      type: 'credit_card',
      label: 'Cartão de crédito',
      detail: '',
    },
  ]

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>('boleto')
  const [cardMask, setCardMask] = useState<CardMask>('9999 9999 9999 9999 9999')

  const shipping = useMemo(() => {
    if (shippingType === 'em casa') {
      if (_.isEmpty(userData)) return {}

      return {
        delivered: 'em casa',
        postalCode: userData.addresses[0]?.postalCode,
        street: userData.addresses[0]?.street,
        city: userData.addresses[0]?.city,
        state: userData.addresses[0]?.state,
        number: userData.addresses[0]?.number,
        complement: userData.addresses[0]?.complement,
        neighborhood: userData.addresses[0]?.neighborhood,
      }
    }

    return {
      delivered: 'na escola',
      postalCode: school.zipCode,
      street: school.street,
      neighborhood: school.neighborhood,
      city: school.city,
      state: school.state,
      number: school.streetNumber,
      complement: '',
    }
  }, [shippingType, userData])

  const handleSelectPaymentMethod = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const clickPaymentMethod = e.currentTarget.id

      const item = paymentMethodRefs.current.find(
        (ref) => ref.value === clickPaymentMethod,
      )

      if (item) {
        item.checked = true
        setPaymentMethod(clickPaymentMethod as PaymentMethods)
      }
    },
    [],
  )

  const cartToOrderAreaType = useCallback(() => {
    const orderAreaTypesMap = new Map<string, OrderAreaType>()
    cart.forEach(({ areaType }: KitsProps) => {
      if (!orderAreaTypesMap.get(`${areaType}`))
        orderAreaTypesMap.set(`${areaType}`, {
          orderAreas: [] as OrderArea[],
          price: 0,
          areaType: `api/area_types/${areaType}`,
        } as OrderAreaType)
    })

    cart.forEach((collection) => {
      const areaType = `${collection.areaType}`
      const orderAreaType = orderAreaTypesMap.get(areaType) as OrderAreaType

      orderAreaType.price += collection.value
      orderAreaType.orderAreas.push({
        price: collection.value,
        area: `api/areas/${collection.id}`,
        orderProducts: collection.products.map((prod) => ({
          ...prod,
          price: prod.value,
          value: null,
          id: null,
        })),
      })

      orderAreaTypesMap.set(areaType, orderAreaType)
    })

    const orderAreaTypes = [] as OrderAreaType[]
    orderAreaTypesMap.forEach((orderAreaType) =>
      orderAreaTypes.push(orderAreaType),
    )
    return orderAreaTypes
  }, [cart])

  const validCard = (number: string | undefined) => {
    if (!number) return

    const numberValidation = valid.number(number)

    if (numberValidation.isValid) {
      if (cardFlags.some((flag) => flag === numberValidation.card?.type)) {
        formRef.current?.setFieldError('number', '')
      } else {
        formRef.current?.setFieldError('number', 'Número do cartão inválido')

        throwError(
          'Ocorreu um problema',
          'Verifique se a bandeira do seu cartão é aceita',
        )
      }
    } else {
      formRef.current?.setFieldError('number', 'Número do cartão inválido')
      throwError('Ocorreu um problema', 'Número do cartão inválido')
    }
  }

  const creditCardSchema = Yup.object().shape({
    number: Yup.string().required('O número  do cartão é requerido'),

    expirationDate: Yup.string().test(
      '',
      'Data de expiração do cartão inválida',
      (value) => valid.expirationDate(value).isValid,
    ),

    cvv: Yup.string().test(
      '',
      'Código de segurança(CVV) do cartão inválido',
      (value) => {
        const numberValidation = valid.number(
          formRef.current?.getFieldValue('number'),
        )
        return valid.cvv(value, numberValidation.card?.code.size).isValid
      },
    ),

    holderName: Yup.string().required('Nome do titular do cartão é requerido'),
    cpf: Yup.string().test('', 'O numero de CPF é inválido', (value) =>
      cpfValidation(value || ''),
    ),
    installments: Yup.number()
      .min(1, 'Selecione o número de parcelas')
      .required(),
  })

  const handleSubmit = useCallback(
    async (creditCardDataForm: CreditCardForm) => {
      try {
        formRef.current?.setErrors({})
        setIsLoading(true)

        let orderPayload = {
          customer: `api/users/${user.id}`,
          student: {
            name: preIdentification.student,
            guardian: `api/users/${user.id}`,
          },
          amount: 1,
          installments: 1,
          paymentMethod,
          voucher: `api/vouchers/${voucher.id}`,
          price: orderTotalValue,
          campaign: `api/campaigns/${campaign}`,
          school: `api/schools/${school.id}`,
          segment: `api/segments/${segment.id}`,
          serie: `api/series/${serie.id}`,
          postalCode: shipping.postalCode,
          street: shipping.street,
          city: shipping.city,
          state: shipping.state,
          neighborhood: shipping.neighborhood,
          number: shipping.number,
          complement: shipping.complement,
          orderAreaTypes: cartToOrderAreaType(),
        } as OrderPayload

        if (paymentMethod === 'credit_card') {
          const isValid = await creditCardSchema
            .validate(creditCardDataForm, {
              abortEarly: false,
            })
            .catch((err) => {
              const validationErrors = getValidationErrors(err)
              formRef.current?.setErrors(validationErrors)
            })

          validCard(creditCardDataForm.number)

          if (!isValid) return

          const creditCardNumber = creditCardDataForm.number.replaceAll(' ', '')

          orderPayload = {
            ...orderPayload,
            installments: Number(creditCardDataForm.installments),
            creditCard: {
              number: creditCardNumber,
              expMonth: creditCardDataForm.expirationDate.split('/')[0],
              expYear: `20${creditCardDataForm.expirationDate.split('/')[1]}`,
              cvv: creditCardDataForm.cvv,
              flag: valid.number(creditCardNumber).card?.niceType,
            },
          }
        }

        await makePayment(orderPayload)

        push({ pathname: 'success', state: orderPayload.paymentMethod })
      } catch (err) {
        if (err instanceof Error) {
          createModal({
            title: err.name,
            description: err.message,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [paymentMethod, campaign],
  )

  const makePayment = useCallback(async (orderPayload: OrderPayload) => {
    try {
      await api.post('api/orders', orderPayload)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.detail

        if (String(errorMessage).includes(':'))
          throwError('Erro ao realizar pedido', errorMessage.split(':')[1])

        throwError(
          'Erro ao realizar pedido',
          errorMessage ||
            'Houve algum erro ao realizar o pagamento. Tente novamente mais tarde',
        )
      } else {
        console.log('err non prev', err)
      }
    }
  }, [])

  const handleChangeCardNumber = useCallback((data) => {
    const { value } = data.target

    if (value) {
      const prefix = value.slice(0, 2)
      switch (prefix) {
        case '34':
        case '37':
        case '36':
        case '38':
          setCardMask('9999 999999 999999')
          break
        case '30':
          if (value[2] >= '0' && value[2] <= '5')
            setCardMask('9999 999999 999999')
          else setCardMask('9999 9999 9999 9999 9999')
          break
        default:
          setCardMask('9999 9999 9999 9999 9999')
      }
    }
  }, [])

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Container>
      <Steps current={4} />

      {orderTotalValue === school.shippingPrice ? (
        <div className="loading" />
      ) : (
        <PaymentContent>
          <h1>Itens do pedido</h1>

          {!_.isEmpty(cart) && (
            <>
              {cart.map((kit) => (
                <Kit key={kit.id}>
                  <span>
                    {`${kit.name} ${serie?.name} - `}
                    <strong>{segment?.name}</strong>
                  </span>

                  <strong>{formatValue(kit.value)}</strong>
                </Kit>
              ))}

              <Kit>
                <span>Frete</span>

                <strong>{formatValue(school.shippingPrice)}</strong>
              </Kit>

              <Kit className="total">
                <p>Total da compra</p>

                <strong>{formatValue(orderTotalValue)}</strong>
              </Kit>
            </>
          )}

          <h1>Método de pagamento</h1>

          <Form className="paymentMethod" onSubmit={handleSubmit} ref={formRef}>
            <div className="paymentMethodContainer">
              {paymentMethods.map((payment, i) => {
                return (
                  <button
                    key={payment.type}
                    type="button"
                    id={payment.type}
                    onClick={(e) => handleSelectPaymentMethod(e)}>
                    <span>
                      {payment.label}
                      <p>{payment.detail}</p>
                    </span>

                    <input
                      ref={(ref) => {
                        if (ref) paymentMethodRefs.current[i] = ref
                      }}
                      name="paymentMethod"
                      value={payment.type}
                      type="radio"
                      defaultChecked={i === 0}
                    />
                  </button>
                )
              })}
            </div>

            {paymentMethod === 'credit_card' && (
              <div className="cardForm">
                <div>
                  <strong>Nome do titular</strong>
                  <Input
                    name="holderName"
                    placeholder="Nome como está no cartão"
                  />
                </div>

                <div>
                  <strong>Número do cartão</strong>
                  <Input
                    name="number"
                    mask={cardMask}
                    onChange={handleChangeCardNumber}
                  />
                </div>

                <div className="cardFormRow">
                  <div>
                    <strong>Validade</strong>
                    <Input
                      name="expirationDate"
                      mask="99/99"
                      placeholder="mm/aa"
                    />
                  </div>

                  <div>
                    <strong>CVV</strong>
                    <Input name="cvv" mask="9999" />
                  </div>
                </div>

                <div>
                  <strong>CPF do titular</strong>
                  <Input
                    name="cpf"
                    mask="999.999.999-99"
                    placeholder="000.000.000-00"
                  />
                </div>

                <div>
                  <strong>Parcelas</strong>
                  <Select name="installments" defaultValue={0}>
                    <option value={0} disabled hidden>
                      Selecione uma parcela
                    </option>

                    {school.installments
                      ?.filter((instalment) => instalment <= 12)
                      ?.map((installment) => (
                        <option value={installment} key={String(installment)}>
                          {`${installment}x de ${formatValue(
                            orderTotalValue / installment,
                          )}`}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            )}

            <Button
              type="submit"
              className="submitButton"
              color="secondary"
              isLoading={isLoading}>
              Continuar
            </Button>
          </Form>
        </PaymentContent>
      )}
    </Container>
  )
}

export default Payment
