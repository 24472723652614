import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

export const Container = styled.div`
  width: 100%;
  padding: 0 3rem;

  > div {
    position: relative;
    width: 100%;
    max-width: 1067px;
    margin: 3.5rem auto 0;
    padding: 1rem 3.5rem;
    border-radius: 8px;
    box-shadow: 0px 8px 15px rgba(141, 141, 141, 0.25);
    background: #fffffe;

    border: 2px solid ${colors.primaryLight};

    > div {
      width: 95%;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      h3 {
        font-size: ${sizes.sm};
        font-weight: 500;
        color: ${colors.gray};

        margin-bottom: 4px;
      }

      span {
        line-height: 150%;
        color: ${colors.grayDark};
      }

      .segment {
        min-width: 30%;
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem;
      }
    }

    > button {
      position: absolute;
      top: 1rem;
      right: 2rem;

      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f4f5ff;
      border: none;

      > svg {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);

        color: ${colors.primary};
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    > div {
      padding: 1rem 1.5rem;

      > div {
        flex-direction: column;
        gap: 1rem;

        /* School */
        > div:first-child {
          margin-right: 2rem;
        }
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 0;

    > div {
      span {
        font-size: 14px;
      }
    }
  }
`
