import React from 'react'

import Container from './styles'

interface Props {
  step: number
  img: string
  title: string
  description: string
}

const CardJourney: React.FC<Props> = ({
  step,
  img,
  title,
  description,
}: Props) => {
  return (
    <Container>
      <div className="step-circle">
        <span className="step-number">{step.toString().padStart(2, '0')}</span>
      </div>
      <img src={img} alt={`passo ${step}`} />
      <h3>{title}</h3>
      <p>{description}</p>
    </Container>
  )
}

export default CardJourney
