import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import _ from 'lodash'
import api from 'services/api'

import { useAuth } from 'hooks/auth'
import { useTerm } from 'hooks/term'

import { Form } from '@unform/web'
import { FormHandles, Scope } from '@unform/core'
import { maskTelephone } from 'utils/mask'

import Button from 'components/Button'
import Input from 'components/Form/Input'
import Radio from 'components/Form/Radio'
import Checkbox from 'components/Form/Checkbox'

import { getValidationErrors, validationErrorsArray } from 'utils/validation'
import Person from './Person'
import Company from './Company'

import { UpdateContainer, FormContent } from './styles'
import { UserFormProps } from '../RegisterForm'

const UpdateForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { userData, getUserData } = useAuth()
  const { termToId, handleToggleModalTerm, terms } = useTerm()
  const [documentType, setDocumentType] = useState('')

  const [invalidTerms, setInvalidTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { push } = useHistory()

  const handleSubmit = useCallback(
    async (updateData: UserFormProps) => {
      try {
        setIsLoading(true)

        const registerSchema = Yup.object().shape({
          phone: Yup.string().min(10).max(11).required(),
          address: Yup.object().shape({
            postalCode: Yup.string().min(8).required(),
            street: Yup.string().required(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            neighborhood: Yup.string().required(),
            number: Yup.number().required(),
            complement: Yup.string(),
          }),
          terms: Yup.object().shape(
            Object.fromEntries(
              terms.map((term) => {
                return term.isActive
                  ? [
                      term.title,
                      term.isRequired
                        ? Yup.boolean().oneOf([true])
                        : Yup.boolean(),
                    ]
                  : []
              }),
            ),
          ),
        })

        const formattedUpdateData = {
          terms: updateData.terms,
          address: {
            ...updateData.address,
            postalCode: updateData.address.postalCode.replace('-', ''),
          },
          phone: updateData.phone.replace(/\D/g, ''),
          termAccepteds: Object.entries(updateData.terms)
            .map(([term, accepted]) =>
              accepted
                ? { term: `/api/terms/${termToId(term).id}` }
                : undefined,
            )
            .filter((term) => term !== undefined),
        }

        await registerSchema.validate(formattedUpdateData, {
          abortEarly: false,
        })
        setInvalidTerms(false)

        await api.patch(`/api/users/${userData.id}`, formattedUpdateData)

        if (userData.addresses.length > 0) {
          await api.patch(
            `/api/addresses/${userData.addresses[0].id}`,
            formattedUpdateData.address,
          )
        }

        push('/revision')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err)

          formRef.current?.setErrors(validationErrors)
          console.log(validationErrors)
          setInvalidTerms(
            validationErrorsArray(
              ...terms.map((term) =>
                term.isActive ? validationErrors[`terms.${term.title}`] : '',
              ),
            ),
          )
        }
      } finally {
        setIsLoading(false)
      }
    },
    [userData, terms],
  )

  const hasUserData = useMemo(() => !_.isEmpty(userData), [userData])

  const initialData = useMemo(() => {
    if (!hasUserData) return {}

    setDocumentType(userData.documentType)
    const { addresses } = userData

    return {
      documentType: userData.documentType,
      name: userData.name,
      phone: maskTelephone(userData.phone),
      address: { ...addresses[0] },
      termAccepteds: userData.termAccepteds,

      cpf: userData.cpf,
      rg: userData.rg,

      cnpj: userData.cnpj,
      hasStateSignup: userData.stateRegistration ? 'Sim' : 'Não',
      stateSignup: userData.stateRegistration || '',
      citySignup: userData.municipalRegistration || '',
      companyName: userData.companyName,
    }
  }, [userData])

  const setField = (name: string, value: string) => {
    formRef.current?.setFieldValue(name, value)
    formRef.current?.setFieldError(name, '')
  }

  const handleChangePostalCode = useCallback(async (e) => {
    const { value } = e.target

    if (value.length === 9) {
      try {
        const { data } = await api.post(`/api/address/by-cep`, {
          cep: String(value).replace('-', ''),
        })

        setField('address.state', data.state)
        setField('address.city', data.city)
        setField('address.neighborhood', data.neighborhood)
        setField('address.street', String(data.street).split('-')[0])

        formRef.current?.setFieldError('address.postalCode', '')
      } catch (err) {
        formRef.current?.setFieldError('address.postalCode', 'CEP inválido')
        setField('address.state', '')
        setField('address.city', '')
        setField('address.neighborhood', '')
        setField('address.street', '')
      }
    }
  }, [])

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    initialData.termAccepteds?.forEach((term) =>
      formRef.current?.setFieldValue(`terms.${term.term.title}`, true),
    )

    formRef.current?.setFieldValue('hasStateSignup', initialData.hasStateSignup)
  }, [initialData, terms])

  return (
    <UpdateContainer>
      {hasUserData ? (
        <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
          <h2>Atualizar cadastro</h2>

          <div className="radio_container">
            <Radio
              name="documentType"
              options={[
                { id: 'CPF', label: 'Pessoa física' },
                { id: 'CNPJ', label: 'Pessoa jurídica' },
              ]}
              disabled
            />
          </div>

          <div className="main_form">
            {documentType === 'CPF' ? (
              <Person formRef={formRef} />
            ) : (
              <Company formRef={formRef} />
            )}

            <Scope path="address">
              <FormContent>
                <span>
                  Endereço para faturamento da nota fiscal e envio do material
                  didático:
                </span>
                <span className="span" />

                <div>
                  <strong>CEP:</strong>
                  <Input
                    name="postalCode"
                    mask="99999-999"
                    onBlur={handleChangePostalCode}
                    placeholder="00000-000"
                  />

                  <a
                    className="cep_info"
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="blank">
                    Não sei meu CEP
                  </a>
                </div>

                <div>
                  <strong>Rua:</strong>
                  <Input name="street" />
                </div>

                <div>
                  <strong>Número:</strong>
                  <Input name="number" />
                </div>

                <div>
                  <strong>Complemento (opcional):</strong>
                  <Input name="complement" />
                </div>

                <div>
                  <strong>Bairro:</strong>
                  <Input name="neighborhood" />
                </div>

                <div>
                  <strong>Cidade:</strong>
                  <Input name="city" disabled />
                </div>

                <div>
                  <strong>Estado:</strong>
                  <Input name="state" disabled />
                </div>
              </FormContent>
            </Scope>

            <Scope path="terms">
              <div className="terms_container">
                {terms.map(
                  (term) =>
                    term.isActive && (
                      <Checkbox
                        key={term.id}
                        name={term.title}
                        value={term.title}>
                        <span>
                          {'Li e aceito '}
                          <button
                            type="button"
                            onClick={() => handleToggleModalTerm(term)}>
                            {term.title}
                            {term.isRequired && '*'}
                          </button>
                        </span>
                      </Checkbox>
                    ),
                )}

                {invalidTerms && (
                  <span className="error">
                    Verifique os termos obrigatórios*
                  </span>
                )}
              </div>
            </Scope>
          </div>

          <Button isLoading={isLoading} type="submit" color="secondary">
            Atualizar cadastro
          </Button>
        </Form>
      ) : (
        <div className="loading" />
      )}
    </UpdateContainer>
  )
}

export default UpdateForm
