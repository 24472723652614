import styled, { css } from 'styled-components'
import { colors } from 'styles/styleguide'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;

  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: ${(props) => (props.isLoading ? 'auto' : 'pointer')};

  pointer-events: ${(props) => props.isLoading && 'none'};

  ${(props) => {
    switch (props.color) {
      case 'primary':
        return css`
          & {
            background-color: ${props.isLoading
              ? colors.primaryDark
              : colors.primary};
          }
          &:hover,
          &:focus {
            background-color: ${colors.primary};
          }
        `
      case 'secondary':
        return css`
          & {
            background-color: ${props.isLoading
              ? colors.secondaryDark
              : colors.secondary};
          }
          &:hover,
          &:focus {
            background-color: ${colors.secondaryDark};
          }
        `
      case 'transparent':
        return css`
          & {
            background-color: transparent;
            border: transparent;
          }
        `
      default:
        return css`
          & {
            background-color: transparent;
            border: 2px solid ${colors.secondary};
          }
        `
    }
  }}

  .loading {
    width: 1.2rem;
    height: 1.2rem;

    margin-left: 1rem;

    border: 2px solid #fff;
    border-top: 2px solid transparent;
    border-radius: 50%;

    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  transition: background-color 0.3s;
`
