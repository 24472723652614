import styled from 'styled-components'
import { colors, sizes } from 'styles/styleguide'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fffffe;
  width: 15.25rem;

  border-radius: ${sizes.base};
  padding: 3rem 1.5rem 1.5rem;
  box-shadow: 8px 8px 24px -4px rgba(117, 95, 251, 0.1);

  > .step-circle {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: ${colors.secondary};
    color: #ffffff;

    position: absolute;
    top: -1.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > .step-number {
      font-size: ${sizes.h2};
      font-weight: 700;
    }
  }

  > img {
    margin-bottom: 2rem;
  }

  > h3 {
    color: ${colors.primaryDark};
    font-size: ${sizes.lg};
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 0.5rem;
  }

  > p {
    color: ${colors.grayDark};
    font-size: ${sizes.sm};
    font-weight: 400;
    line-height: 150%;
  }
`
export default Container
