import styled from 'styled-components'
import { responsives } from 'styles/styleguide'

interface ContainerProps {
  isVisible: boolean
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;

  transition: opacity 0.3s ease-out;
  z-index: 100;

  ${(props) =>
    props.isVisible
      ? `& {

        opacity: 1;
        visibility: visible;
      }`
      : `& {
        opacity: 0;
        visibility: hidden;
      }`}
`

export const Modal = styled.div`
  position: relative;
  max-height: 90vh;
  max-width: 360px;
  width: 100%;

  border-radius: 0.5rem;
  background: #fff;
  z-index: 101;

  display: grid;

  overflow-x: hidden;

  > .img-container {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 0 0 1.5rem 1.5rem;
    overflow: hidden;

    > img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      width: 120%;
      object-fit: cover;
    }
  }

  > .circle {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: #fff;

    > svg {
      position: absolute;
      bottom: 0.75rem;
      left: 0.75rem;
      cursor: pointer;
    }
  }

  @media (min-width: ${responsives.largeDevices}) {
    grid-template-columns: auto 1fr;
    width: auto;
    max-width: 930px;

    > .img-container {
      width: 360px;
      height: 100%;
      border-radius: 0 1.5rem 1.5rem 0;

      > img {
        height: 130%;
      }
    }
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`
