import styled from 'styled-components'
import { colors, sizes, responsives } from 'styles/styleguide'

import Bg from 'assets/pngs/help-bg.png'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 5rem);
`

export const Content = styled.div`
  display: grid;
  place-items: center;

  padding: 1.5rem 1rem;
  padding-bottom: 200px;

  > div {
    max-width: 1066px;
  }

  > h2 {
    font-size: ${sizes.h3};
    font-weight: 700;
    color: ${colors.grayDark};
    margin-bottom: 2rem;
  }

  > .accordion {
    width: 100%;

    display: grid;
    gap: 1rem;
    margin-bottom: 2.5rem;
  }

  > .needs-help {
    display: grid;
    place-items: center;
    gap: 2rem;

    > img {
      width: 300px;
      object-fit: cover;
    }

    > div {
      max-width: 320px;
      text-align: center;
      display: grid;
      place-items: center;
      gap: 1rem;
      margin-bottom: 4rem;

      > .title {
        color: ${colors.grayDark};
        font-size: ${sizes.lg};
        font-weight: 700;
      }

      > .email {
        width: 100%;
        padding: 1rem;
        border-radius: 8px;
        background: ${colors.primaryLight};

        a {
          text-decoration: none;
          font-size: ${sizes.lg};
          font-weight: 700;
          color: ${colors.primaryDark};

          &:hover {
            border-bottom: 2px solid #2ec3e5;
          }
        }
      }

      > .tip {
        color: #81817d; /* grayDark */

        > span {
          color: ${colors.grayDark};
          font-weight: 500;
        }
      }
    }
  }

  @media (min-width: ${responsives.mediumDevices}) {
    padding: 3rem;
    padding-bottom: 200px;
  }

  @media (min-width: ${responsives.largeDevices}) {
    > .needs-help {
      grid-template-columns: repeat(2, 1fr);
      place-items: center normal;

      > img {
        order: 1;
        width: 100%;
      }

      > div {
        place-items: normal;
        text-align: left;

        > .email {
          text-align: center;
        }
      }
    }
  }
`

export const Background = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  width: 100%;
  max-width: 1366px;
  height: 200px;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: bottom -100px right -600px;
  @media (max-width: ${responsives.largeDevices}) {
    background-position: bottom -120px right -600px;
  }
`
