import React, { useCallback, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import { useAlert } from 'hooks/alert'
import { useOrder } from 'hooks/order'

import { getValidationErrors } from 'utils/validation'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import Button from 'components/Button'

import { PreIdentificationProps as ValidadeIdentificationProps } from 'interfaces'

import { Content, HiddenInput } from './styles'

interface IdentificationProps {
  forceClose?: () => void
}

const PreIdentificationModal: React.FC<IdentificationProps> = ({
  forceClose,
}: IdentificationProps) => {
  const history = useHistory()
  const location = useLocation()
  const { createModal } = useAlert()
  const { setPreIdentification, school, segment, serie } = useOrder()

  const formRef = useRef<FormHandles>(null)

  const identificationSchema = Yup.object().shape({
    school: Yup.number().required(),
    student: Yup.string().required(),
    segment: Yup.number().required(),
    serie: Yup.number().required(),
  })

  const handleSubmit = useCallback(
    async (validateData: ValidadeIdentificationProps) => {
      try {
        formRef.current?.setErrors({})

        await identificationSchema.validate(validateData, { abortEarly: false })

        setPreIdentification(validateData)

        if (location.pathname === '/') history.push({ pathname: 'materials' })
        else if (forceClose) forceClose()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err)

          formRef.current?.setErrors(validationErrors)

          createModal({
            title: 'Preenchimento necessário!',
            description: 'Todos campos são obrigatórios para continuar.',
          })
        }
      }
    },
    [],
  )

  useEffect(() => {
    formRef.current?.setData({
      school: school?.id,
      student: '',
      segment: segment?.id,
      serie: serie?.id,
    })
  }, [school])

  return (
    <Content>
      <h3>Digite os dados do aluno</h3>
      <p>Preencha as informações abaixo para continuar</p>

      <Form ref={formRef} onSubmit={handleSubmit}>
        {school.name && (
          <label className="school" htmlFor="school">
            {school.name}
          </label>
        )}

        <label className="segmentSerie" htmlFor="segment">
          {segment?.name}
          {serie.name && ` - ${serie?.name}`}
        </label>

        <HiddenInput>
          <Input name="school" placeholder="Nome da escola" disabled />
        </HiddenInput>

        <div className="selects">
          <div>
            <Select name="segment" defaultValue="default" disabled hidden>
              <option value="default" disabled hidden>
                Selecione um segmento
              </option>

              <option key={segment?.id} value={segment?.id}>
                {segment?.name}
              </option>
            </Select>
          </div>

          <div>
            <Select name="serie" defaultValue="default" disabled hidden>
              <option value="default" disabled hidden>
                Selecione uma série/ano
              </option>

              <option key={serie?.id} value={serie?.id}>
                {serie?.name}
              </option>
            </Select>
          </div>
        </div>

        <label className="student" htmlFor="student">
          Nome do aluno:
        </label>
        <Input
          id="student"
          name="student"
          placeholder="Nome completo do aluno"
        />

        <Button
          className="submit-preIdentification"
          type="submit"
          color="secondary">
          Continuar
        </Button>
      </Form>
    </Content>
  )
}

export default PreIdentificationModal
