import React from 'react'

import { AlertProvider } from './alert'
import { OrderProvider } from './order'
import { AuthProvider } from './auth'
import { TermProvider } from './term'

const AppProvider: React.FC = ({ children }) => {
  return (
    <AlertProvider>
      <TermProvider>
        <AuthProvider>
          <OrderProvider>{children}</OrderProvider>
        </AuthProvider>
      </TermProvider>
    </AlertProvider>
  )
}

export default AppProvider
