import styled from 'styled-components'
import { colors, sizes, responsives } from 'styles/styleguide'

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;

  opacity: 1;
  z-index: 100;

  > .alert-content {
    min-width: 300px;
    position: fixed;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 2rem;
    border-radius: 8px;

    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    z-index: 101;

    > svg {
      align-self: flex-end;
    }

    > svg:hover {
      cursor: pointer;
    }

    > h3 {
      color: ${colors.grayDark};
      font-size: ${sizes.h3};
      text-align: center;
    }

    > p {
      color: ${colors.gray};
      height: 120px;
      display: flex;
      align-items: center;
      text-align: center;
      max-width: 75%;
    }

    > button {
      max-width: 75%;
      width: 100%;
      color: ${colors.secondaryDark};
    }
  }

  > .overlay {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    backdrop-filter: blur(1px);
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${responsives.mediumDevices}) {
    .content {
      width: 90%;
    }
  }
`
