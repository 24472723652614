import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import * as Yup from 'yup'
import _ from 'lodash'
import { useTerm } from 'hooks/term'

import { FormHandles, Scope } from '@unform/core'

import api from 'services/api'
import Modal from 'components/Modal'
import Checkbox from 'components/Form/Checkbox'
import Button from 'components/Button'

import { getValidationErrors, validationErrorsArray } from 'utils/validation'
import { useAuth } from 'hooks/auth'
import { Container } from './styles'

interface TermsModalProps {
  visible: boolean
  requiredTerms: { id: string }[] | null
  handleOnClose(): void
}

const TermsModal: React.FC<TermsModalProps> = ({
  requiredTerms,
  visible = false,
  handleOnClose,
}) => {
  const formRef = useRef<FormHandles>(null)
  const [invalidTerms, setInvalidTerms] = useState(false)

  const { handleToggleModalTerm, terms, termToId } = useTerm()
  const { user, userData, getUserData } = useAuth()

  const hasUserData = useMemo(() => !_.isEmpty(userData), [userData])
  const initialData = useMemo(() => {
    if (!hasUserData) return {}

    return {
      termAccepteds: userData.termAccepteds,
    }
  }, [userData])

  const handleSubmitTerms = useCallback(
    async (data) => {
      setInvalidTerms(false)

      try {
        const termsSchema = Yup.object().shape({
          terms: Yup.object().shape(
            Object.fromEntries(
              terms.map((term) => {
                return term.isActive
                  ? [
                      term.title,
                      term.isRequired
                        ? Yup.boolean().oneOf([true])
                        : Yup.boolean(),
                    ]
                  : []
              }),
            ),
          ),
        })

        await termsSchema.validate(data, {
          abortEarly: false,
        })

        if (requiredTerms) {
          const termAccepteds = Object.entries(data.terms)
            .map(([term, accepted]) =>
              accepted
                ? { term: `/api/terms/${termToId(term).id}` }
                : undefined,
            )
            .filter((term) => term !== undefined)

          await api.patch(`/api/users/${user.id}`, {
            termAccepteds,
          })
        }

        handleOnClose()
      } catch (err) {
        console.log(err)
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err)

          formRef.current?.setErrors(validationErrors)

          setInvalidTerms(
            validationErrorsArray(
              ...terms.map((term) =>
                term.isActive ? validationErrors[`terms.${term.title}`] : '',
              ),
            ),
          )
        }
      }
    },
    [setInvalidTerms, terms],
  )

  useEffect(() => {
    initialData.termAccepteds?.forEach((term) =>
      formRef.current?.setFieldValue(`terms.${term.term.title}`, true),
    )
  }, [initialData, terms])

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Modal visible={visible}>
      <Container
        ref={formRef}
        onSubmit={handleSubmitTerms}
        initialData={initialData}>
        <h3>
          Os termos foram atualizados e precisamos que você concorde com eles
          novamente para utilizar a plataforma.
        </h3>

        <Scope path="terms">
          {terms.map(
            (term) =>
              term.isActive && (
                <Checkbox key={term.id} name={term.title} value={term.title}>
                  <span>
                    {'Li e aceito '}
                    <button
                      type="button"
                      onClick={() => handleToggleModalTerm(term)}>
                      {term.title}
                      {term.isRequired && '*'}
                    </button>
                  </span>
                </Checkbox>
              ),
          )}
        </Scope>

        {invalidTerms && (
          <span className="error">Verifique os termos obrigatórios*</span>
        )}

        <Button type="submit">Atualizar termos</Button>
      </Container>
    </Modal>
  )
}

export default TermsModal
