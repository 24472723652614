import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import backgroundImg from 'assets/pngs/header-bg.png'
import backgroundMobileImg from 'assets/pngs/header-mobile-bg.png'

interface HamburgerMenuProps {
  isActive: boolean
}

export const Container = styled.header`
  position: relative;
`

export const HeaderContainer = styled.div`
  width: 100%;

  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${colors.primaryDark};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 8rem;

  > img {
    z-index: 2;
    max-width: 187px;
    margin-right: 0.5rem;
  }

  > button {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 1rem;
      height: 1rem;

      margin-right: 0.5rem;
    }
  }

  .navigation_menu {
    width: 100%;
  }

  .hamburger_menu {
    display: none;
  }

  .user_data_container {
    width: 202px;

    > svg {
      display: none;
    }

    > button {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      background: transparent;
      border: 0;

      color: #fff;

      > span {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;

        > b {
          height: ${sizes.base};
          width: 170px;
          overflow: hidden;

          font-size: ${sizes.base};
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 0.5rem;
        }
      }

      > p {
        width: 180px;
        font-size: ${sizes.sm};
        color: ${colors.primaryLight};

        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (max-width: calc(${responsives.largeDevices})) {
    padding: 1rem;
  }

  @media (max-width: calc(${responsives.mediumDevices})) {
    background-image: url(${backgroundMobileImg});

    > img {
      width: 127px;
    }

    .user_data_container {
      width: auto;
      background: transparent;

      > svg {
        display: flex;
        color: #fff;
      }

      > button {
        display: none;
      }
    }

    .navigation_menu {
      display: none;
    }

    .hamburger_menu {
      padding: 0;
      display: flex;
    }
  }
`

export const HamburgerMenu = styled.div<HamburgerMenuProps>`
  display: none;

  @media (max-width: calc(${responsives.mediumDevices})) {
    position: absolute;
    display: ${(props) => (props.isActive ? `flex` : `none`)};
    width: 100%;
    flex-flow: column;
    z-index: 3;

    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    background: #3500b7;

    transition: top 0.3s;
    -o-transition: top 0.3s;
    -moz-transition: top 0.3s;
    -webkit-transition: top 0.3s;

    > button {
      margin-top: 1rem;
    }

    > div {
      display: flex;
      flex-flow: column;
      > a {
        font-weight: 700;
        color: #fff;

        text-decoration: none;
        text-transform: uppercase;

        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #4106d3;

        + a {
          margin-left: 0;
        }
        &:hover,
        &.active {
          border-bottom: 1px solid #2ec3e5;
          margin-bottom: 0;
        }
      }
    }
  }
`

export const UserDropdown = styled.div`
  z-index: 2;
  width: 12rem;

  position: absolute;
  bottom: -170%;
  right: 8rem;

  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;

  > ul {
    list-style-type: none;

    > li {
      display: flex;
      align-items: center;

      > svg {
        color: ${colors.primaryDark};
        margin-right: 1rem;
      }

      > a,
      button {
        width: 100%;
        color: ${colors.gray};
        text-decoration: none;
        text-align: start;

        cursor: pointer;

        border: 0;
        background: transparent;

        &:hover {
          color: ${colors.primaryDark};
          transition: color 0.2s;
        }
      }
    }

    > li + li {
      margin-top: 1rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: -10%;
    right: 5%;
    width: 0;
    border-bottom: 1rem solid #fff;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
  }

  @media (max-width: calc(${responsives.largeDevices})) {
    right: 1rem;
  }

  @media (max-width: ${responsives.largeDevices}) {
    bottom: -180%;
  }

  @media (max-width: calc(${responsives.mediumDevices})) {
    position: absolute;
    left: 0;
    bottom: -185%;
    width: 100%;

    border-radius: 0 0 8px 8px;

    > ul {
      > li {
        display: flex;
        flex-flow: row-reverse;

        text-align: end;

        > a,
        button {
          color: ${colors.gray};
          font-weight: 700;
          margin-right: 1rem;
        }
      }
    }

    &:before {
      border: 0px;
    }
  }
`
