import React from 'react'

import { useOrder } from 'hooks/order'

import Button from 'components/Button'

import { BiPencil } from 'react-icons/bi'

import { Container } from './styles'

interface IdentificationProps {
  handleEdit?: () => void
}

const PreIdentification: React.FC<IdentificationProps> = ({
  handleEdit,
}: IdentificationProps) => {
  const { school, preIdentification, segment, serie } = useOrder()
  return (
    <Container>
      <div>
        <div>
          <div>
            <h3>Escola</h3>
            <span>{school?.name}</span>
          </div>

          <div>
            <h3>Aluno</h3>
            <span>{preIdentification.student}</span>
          </div>

          <div className="segment">
            <div>
              <h3>Segmento</h3>
              <span>{segment?.name}</span>
            </div>

            <div>
              <h3>Série/Ano</h3>
              <span>{serie?.name}</span>
            </div>
          </div>
        </div>

        <Button onClick={handleEdit}>
          <BiPencil />
        </Button>
      </div>
    </Container>
  )
}

export default PreIdentification
