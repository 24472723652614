import React, { useRef, useCallback, useState } from 'react'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import { useAuth } from 'hooks/auth'
import { useAlert } from 'hooks/alert'

import Input from 'components/Form/Input'
import Button from 'components/Button'
import Modal from 'components/Modal'

import { getValidationErrors } from 'utils/validation'

import NotebookGirlImg from 'assets/pngs/pexels-andrea-piacquadio.png'

import { FiChevronLeft } from 'react-icons/fi'
import { colors } from 'styles/styleguide'
import { LoginContainer, ForgotPasswordContainer } from './styles'

interface LoginProps {
  visible: boolean
  onClose: () => void
}

const LoginModal: React.FC<LoginProps> = ({ visible, onClose }: LoginProps) => {
  const [toggleForgotModal, setToggleForgotModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formSignInRef = useRef<FormHandles>(null)
  const formResetPasswordRef = useRef<FormHandles>(null)

  const { signIn, resetPassword } = useAuth()
  const { createModal } = useAlert()

  const signInSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
  })

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required().email(),
  })

  const handleSignIn = useCallback(async (authParams) => {
    try {
      setIsLoading(true)

      formSignInRef.current?.setErrors({})

      await signInSchema.validate(authParams, { abortEarly: false })

      await signIn(authParams)
      onClose()
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = getValidationErrors(err)

        formSignInRef.current?.setErrors(validationErrors)
      } else {
        createModal({
          title: 'Ops, algo deu errado!',
          description: 'Seu login ou senha estão incorretos.',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  const handleForgotPassword = useCallback(async (forgotPassParams) => {
    try {
      formResetPasswordRef.current?.setErrors({})

      await forgotPasswordSchema.validate(forgotPassParams, {
        abortEarly: false,
      })

      await resetPassword(forgotPassParams.email)

      handleToggleForgotPasswordModal()
      createModal({
        title: 'Senha enviada!',
        description: 'Uma nova senha foi enviada para seu e-mail.',
      })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = getValidationErrors(err)

        formResetPasswordRef.current?.setErrors(validationErrors)
      } else {
        createModal({
          title: 'Senha enviada!',
          description: 'Uma nova senha foi enviada para seu e-mail.',
        })
      }
    }
  }, [])

  const handleToggleForgotPasswordModal = useCallback(() => {
    setToggleForgotModal((state) => !state)
  }, [])

  return (
    <Modal visible={visible} img={NotebookGirlImg} onClose={onClose}>
      {!toggleForgotModal ? (
        <LoginContainer>
          <h1>Entrar</h1>
          <p>Digite os campos abaixo para continuar</p>

          <Form
            ref={formSignInRef}
            onSubmit={handleSignIn}
            initialData={{
              email: '',
              password: '',
            }}>
            <h3>E-mail:</h3>
            <Input
              id="email"
              name="email"
              placeholder="exemplo@exemplo.com.br"
            />

            <h3>Senha:</h3>
            <Input
              name="password"
              type="password"
              placeholder="*******"
              isPassword
            />

            <button type="button" onClick={handleToggleForgotPasswordModal}>
              Esqueci minha senha
            </button>

            <div className="button_container">
              <Button isLoading={isLoading} type="submit" color="secondary">
                Entrar
              </Button>
            </div>
          </Form>
        </LoginContainer>
      ) : (
        <ForgotPasswordContainer>
          <FiChevronLeft
            size={24}
            color={colors.secondary}
            onClick={handleToggleForgotPasswordModal}
          />

          <h1>Recuperar senha</h1>

          <p>
            Digite o e-mail cadastrado para receber as orientações de
            recuperação de senha.
          </p>

          <Form ref={formResetPasswordRef} onSubmit={handleForgotPassword}>
            <h3>E-mail:</h3>
            <Input name="email" placeholder="exemplo@exemplo.com.br" />

            <Button type="submit" color="secondary">
              Enviar
            </Button>
          </Form>
        </ForgotPasswordContainer>
      )}
    </Modal>
  )
}

export default LoginModal
