import axios, { AxiosRequestConfig } from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = sessionStorage.getItem('token')

  /* eslint-disable no-param-reassign */
  if (token) config.headers.Authorization = `bearer ${JSON.parse(token)}`

  return config
})

export default api
