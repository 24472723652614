import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'
import { Form } from '@unform/web'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;

  > h1 {
    width: 900px;
    margin: 0 auto;
    display: flex;
    padding: 2rem 4rem;
  }

  .loading {
    width: 3rem;
    height: 3rem;

    margin: 2rem auto;
    border: 6px solid ${colors.grayLight};
    border-top: 6px solid ${colors.primary};
    border-radius: 50%;

    animation: spin 1.5s linear infinite;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > h1 {
      width: 90%;
      padding: 1rem;
      font-size: ${sizes.h3};
    }
  }
`

export const UserInfo = styled(Form)`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 900px;

  margin: 0 auto 8rem;
  padding: 2rem 4rem;

  border: 1px solid rgba(207, 209, 255, 1);
  border-radius: 4px;

  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.25);

  > h3 {
    color: ${colors.grayDark};
    font-weight: 500;
    font-size: ${sizes.xl};

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 2rem;

    > span {
      > p {
        font-weight: 500;
        font-size: ${sizes.base};
        color: ${colors.grayDark};
      }

      > div {
        width: 95%;
        height: auto;

        margin-top: 0.5rem;
        background: rgba(248, 248, 248, 1);
      }
    }
  }

  > button {
    margin: 2rem auto 0;
    width: 33%;
    color: ${colors.secondary};
  }

  @media (max-width: ${responsives.largeDevices}) {
    width: 90%;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem;

    > div {
      display: flex;
      flex-flow: column;

      > span {
        > div {
          width: 100%;
        }
      }
    }

    > button {
      width: 100%;
    }
  }
`

export const ChangePasswordContainer = styled.div`
  width: 500px;
  background-color: #fff;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  padding: 3rem;

  h3 {
    color: ${colors.grayDark};
    font-size: ${sizes.h3};
    font-weight: 700;
  }

  > p {
    color: ${colors.gray};
    margin-top: 1rem;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-flow: column;
    width: 100%;

    > span {
      margin-top: 2rem;
      > p {
        font-weight: 500;
        font-size: ${sizes.base};
        color: ${colors.grayDark};
      }

      > div {
        width: 100%;
        height: auto;

        margin-top: 0.5rem;
        background: rgba(248, 248, 248, 1);
      }
    }

    > p {
      color: ${colors.primaryDark};
      font-size: ${sizes.sm};
      width: 75%;
      margin-top: 0.5rem;
      line-height: 14.4px;
    }

    > button {
      margin: 3rem auto 0;
      width: 66%;
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    width: 100%;

    padding: 2rem;

    form {
      button {
        width: 100%;
      }
    }
  }
`
