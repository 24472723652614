import React, { useCallback } from 'react'

import Input from 'components/Form/Input'

import { maskTelephone } from 'utils/mask'

import { FormHandles } from '@unform/core'
import { FormContent } from '../styles'

interface PersonFormProps {
  formRef: React.RefObject<FormHandles>
}

const Person: React.FC<PersonFormProps> = ({ formRef }: PersonFormProps) => {
  const handleTelephoneMask = useCallback((data) => {
    const { value } = data.target

    formRef.current?.setFieldValue('phone', maskTelephone(value))
  }, [])

  return (
    <FormContent>
      <div>
        <strong>Nome do responsável:</strong>
        <Input name="name" disabled />
      </div>

      <div>
        <strong>CPF:</strong>
        <Input name="cpf" mask="999.999.999-99" disabled />
      </div>

      <div>
        <strong>RG:</strong>
        <Input name="rg" mask="99.999.999-*" disabled />
      </div>

      <div>
        <strong>Telefone:</strong>
        <Input name="phone" onChange={handleTelephoneMask} />
      </div>
    </FormContent>
  )
}

export default Person
