import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fffffe.png'

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 65px);

  padding: 3rem 2rem;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-position: top -840px right -580px;
  background-repeat: no-repeat;

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem;
  }
`

export const Content = styled.div`
  max-width: 1067px;
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  margin: 0 auto;

  color: ${colors.gray};

  > div:nth-child(2) {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 3rem;

    text-align: center;

    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 3rem;
    background: #fff;

    border: 2px solid #cfd1ff;
    border-radius: 8px;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.25);

    h2 {
      font-size: ${sizes.xl};
      font-weight: 700;
      color: ${colors.primary};
    }

    img {
      max-width: 360px;
    }

    > .buttons {
      display: flex;
      gap: 1rem;

      margin-top: 2rem;
    }
  }

  > .buttons {
    display: none;
  }

  button {
    width: 200px;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > div:nth-child(2) {
      padding: 2.5rem 1rem;
      margin-bottom: 3rem;

      img {
        width: 190px;
      }

      > .buttons {
        display: none;
      }
    }

    > .buttons {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      margin-bottom: 3rem;

      button {
        width: 100%;
      }
    }
  }
`
