import styled from 'styled-components'
import { sizes, responsives, colors } from 'styles/styleguide'

export const LoginContainer = styled.div`
  width: 570px;
  padding: 4rem;

  > h1 {
    color: ${colors.grayDark};
    font-size: ${sizes.xl};
    font-weight: 700;
  }

  > p {
    font-size: ${sizes.base};
    margin-top: 0.5rem;
  }

  > form {
    margin-top: 2rem;

    > h3 {
      color: ${colors.grayDark};
      font-size: ${sizes.base};
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    > button {
      width: 100%;
      background: transparent;
      border: 0;
      margin-top: 1rem;

      color: rgba(65, 5, 211, 1);
      text-decoration: underline;

      text-align: start;

      &:hover {
        cursor: pointer;
      }
    }

    > .button_container {
      display: flex;
      flex-flow: row nowrap;

      margin-top: 4rem;

      > button {
        width: 100%;

        & + button {
          margin-left: 1rem;
          color: ${colors.secondary};
        }
      }
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    width: auto;
    padding: 2rem;

    > h1 {
      text-align: center;
    }

    > form {
      > button {
        text-align: center;
      }

      > .button_container {
        margin-top: 1rem;

        flex-flow: column nowrap;

        > button {
          & + button {
            margin-top: 1rem;
            margin-left: 0rem;
          }
        }
      }
    }
 `

export const ForgotPasswordContainer = styled.div`
  padding: 4rem;
  position: relative;

  > svg {
    position: absolute;
    top: 1%;
    left: 2.5%;

    &:hover {
      cursor: pointer;
    }
  }

  > h1 {
    color: ${colors.grayDark};
    font-size: ${sizes.xl};
    font-weight: 700;
  }

  > p {
    font-size: ${sizes.base};
    color: ${colors.grayDark};
    margin-top: 0.5rem;
  }

  > form {
    margin-top: 2rem;

    > h3 {
      color: ${colors.grayDark};
      font-size: ${sizes.base};
      font-weight: 500;

      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    > button {
      width: 100%;
      margin-top: 5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 2rem;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 2rem;

    > h1 {
      text-align: center;
    }
  }
`
