import styled from 'styled-components'
import { colors } from 'styles/styleguide'

export const Container = styled.div`
  display: flex;
  align-items: center;

  > div {
    position: relative;
    width: 1rem;
    height: 1rem;

    > input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 1rem;
      height: 1rem;

      margin: 0;
      padding: 0;
      border-radius: 4px;

      background: #fff;
      cursor: pointer;

      border: 2px solid ${colors.secondary};

      & + svg {
        pointer-events: none;
        stroke: ${colors.secondary};
        stroke-width: 4px;
        display: none;
      }

      &:checked + svg {
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
      }

      &:disabled {
        opacity: 0.6;
        cursor: default;

        & + svg {
          opacity: 0.6;
        }
      }
    }
  }
`
