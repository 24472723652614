import styled from 'styled-components'
import { responsives, sizes } from 'styles/styleguide'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  > .carousel {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 0 0 ${sizes.xl} ${sizes.xl};
    background: #fff;

    overflow-x: auto;
    overflow-y: hidden;

    scroll-behavior: smooth;
    scroll-snap-stop: always;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;

    &::-webkit-scrollbar {
      display: none;
    }

    > .item {
      position: relative;
      flex: none;
      width: 100%;
      height: 100%;

      scroll-snap-align: center;

      > .content {
        position: absolute;
        top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        color: #fff;

        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          > .title {
            margin-bottom: ${sizes.xs};
          }

          > .subtitle {
            margin-bottom: ${sizes.base};
          }

          a {
            width: fit-content;
            display: none;
            text-decoration: none;

            > button {
              padding: 0.75rem 4rem;
              border: 1px solid #fff;
            }
          }
        }
      }

      > picture > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .navigation {
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      display: flex;
      gap: 1.25rem;

      > .navigation-item {
        display: none;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        border: none;
        cursor: pointer;
      }

      > .active {
        background: #fff533;
      }
    }

    > .navigation {
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
      display: flex;
      gap: 1.25rem;

      > .navigation-item {
        display: none;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        border: none;
        cursor: pointer;
      }

      > .active {
        background: #fff533;
      }
    }
  }

  @media (min-width: ${responsives.mediumDevices}) {
    .carousel {
      > .item > .content {
        top: 4rem;
        > div > a {
          display: block;
          margin: 0 auto 1rem;
        }
      }

      > .navigation > .navigation-item {
        display: block;
      }
    }
  }

  @media (min-width: ${responsives.largeDevices}) {
    .carousel {
      > .item > .content {
        top: 6rem;
        text-align: left;
        flex-direction: row;
        justify-content: space-between;

        > div {
          > .title,
          > .subtitle {
            margin-bottom: 1.2rem;
          }

          > .subtitle {
            max-width: 320px;
          }

          > a {
            margin: 0;
          }
        }
      }
    }
  }
`
