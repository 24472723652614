import React, { useCallback, useMemo, useState } from 'react'
import { FiArrowRight, FiCheck } from 'react-icons/fi'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { formatValue } from 'utils/formatValue'
import { KitsProps, useOrder } from 'hooks/order'

import { Container, Details, ProductImg } from './styles'

interface CardCollectionProps {
  kit: KitsProps
  areaType: number
  type: 'radio' | 'checkbox'
}

const CardProducts: React.FC<CardCollectionProps> = ({
  kit,
  areaType,
  type,
}: CardCollectionProps) => {
  const [openDetails, setOpenDetails] = useState(false)

  const {
    cart,
    toggleKitOnCart,
    segment,
    serie,
    voucher: {
      voucherGroup: {
        serie: { fileUrl },
      },
    },
  } = useOrder()

  const handleToggleOnCloseDetails = () => setOpenDetails((state) => !state)

  const handleToggleKitToCart = useCallback(async () => {
    await toggleKitOnCart(kit, areaType)
  }, [cart, kit])

  const formattedKitValue = useMemo(() => {
    return formatValue(kit.value)
  }, [kit.value])

  const isSelected = useMemo(() => {
    const foundKit = cart.findIndex((kitOnCart) => kitOnCart.id === kit.id)

    return foundKit >= 0
  }, [cart, kit])

  return (
    <>
      <Container type={type} marked={isSelected}>
        <ProductImg src={fileUrl} />

        <div>
          <b>{kit.name}</b>

          <div>
            <p>{segment?.name}</p>
            <p>{serie?.name}</p>
          </div>
        </div>

        <span>
          <b>{formattedKitValue}</b>
          <p>Contém {kit.products.length} itens.</p>
        </span>

        <button
          type="button"
          className="button_details"
          onClick={handleToggleOnCloseDetails}>
          Detalhes do produto
          <FiArrowRight />
        </button>

        <Button type="button" onClick={handleToggleKitToCart}>
          <FiCheck size={12} />
        </Button>
      </Container>

      <Modal visible={openDetails} onClose={handleToggleOnCloseDetails}>
        <Details>
          <h3>Detalhes do produto</h3>
          <p>Produtos referentes ao materiais didáticos</p>

          <div className="scroll">
            <div className="products">
              <div className="label">
                <p>Livro</p>
                <span>Un.</span>
              </div>

              {kit.products.map((product, index) => {
                return (
                  <div key={Number(index)} className="product">
                    <p>{product.name}</p>

                    <span>01</span>
                  </div>
                )
              })}
            </div>
          </div>
        </Details>
      </Modal>
    </>
  )
}

export default CardProducts
