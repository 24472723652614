import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { KitsProps, useOrder } from 'hooks/order'
import { useAuth } from 'hooks/auth'

import Steps from 'components/Steps'
import Button from 'components/Button'
import Modal from 'components/Modal'
import PreIdentificationModal from 'components/PreIdentificationModal'

import { formatValue } from 'utils/formatValue'
import { maskZipCode } from 'utils/mask'

import { BiPencil } from 'react-icons/bi'

import NotebookGirlImg from 'assets/pngs/pexels-andrea-piacquadio.png'

import PreIdentification from 'components/PreIdentification'

import {
  Container,
  Content,
  ItemsContent,
  Item,
  FreightContent,
  Freight,
} from './styles'

const Revision: React.FC = () => {
  const [togglePreIdentification, setTogglePreIdentification] = useState(false)
  const { cart, school, segment, serie, orderTotalValue, shippingType } =
    useOrder()
  const { userData, getUserData } = useAuth()

  const { push } = useHistory()

  const shipping = useMemo(() => {
    if (shippingType === 'em casa') {
      if (_.isEmpty(userData)) return {}

      return {
        delivered: 'em casa',
        postalCode: userData.addresses[0]?.postalCode,
        street: userData.addresses[0]?.street,
        city: userData.addresses[0]?.city,
        state: userData.addresses[0]?.state,
        number: userData.addresses[0]?.number,
        complement: userData.addresses[0]?.complement,
        neighborhood: userData.addresses[0]?.neighborhood,
      }
    }

    return {
      delivered: 'na escola',
      postalCode: school.zipCode,
      street: school.street,
      neighborhood: school.neighborhood,
      city: school.city,
      state: school.state,
      number: school.streetNumber,
      complement: '',
    }
  }, [shippingType, userData])

  const handleTogglePreIdentificationModal = () => {
    setTogglePreIdentification((state) => !state)
  }

  const handleToggleEditionCart = useCallback(() => {
    push('/materials')
  }, [])

  const handleContinue = () => {
    push('payment')
  }

  const item = ({ id, name, value, products }: KitsProps) => (
    <Item key={id}>
      <div>
        <b>{name}</b>

        <div>
          <p>{segment?.name}</p>
          <p>{serie?.name}</p>
          <span>Contém {products.length} itens.</span>
        </div>
      </div>

      <span>
        <b>{formatValue(value)}</b>
        <span>Contém {products.length} itens.</span>
      </span>

      <span className="unity">1 un.</span>
    </Item>
  )

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Container>
      <Content>
        <Steps current={3} />
        <div className="box">
          <PreIdentification handleEdit={handleTogglePreIdentificationModal} />

          <ItemsContent>
            <div>
              <h2>Itens do carrinho</h2>

              <Button onClick={handleToggleEditionCart}>
                <BiPencil /> Editar
              </Button>

              <div>
                {cart
                  .sort((a: KitsProps, b: KitsProps) => {
                    if (a.areaType === b.areaType) return a.id - b.id
                    return a.areaType - b.areaType
                  })
                  .map((kit) => item(kit))}
              </div>
            </div>
          </ItemsContent>

          <FreightContent>
            <div>
              <span>
                ATENÇÃO: A entrega dos materiais didáticos ocorrerá de forma
                fracionada, obedecendo o calendário letivo da escola.
              </span>

              <h2>Frete</h2>

              <Freight>
                <div className="label">
                  <p className="title">Entrega {shipping.delivered}</p>
                  <b>{formatValue(school.shippingPrice)}</b>
                </div>

                <div className="panel">
                  <div>
                    <b>Endereço:</b>
                    <p>
                      {`${shipping.street},
                      ${shipping.number} - ${shipping.neighborhood},
                    ${shipping.city} - ${shipping.state},
                    ${maskZipCode(shipping.postalCode || '')}
                  `}
                    </p>
                  </div>

                  <div>
                    <b>Previsao de entrega:</b>
                    <div>
                      {school.deliveries?.map((delivery, i) => (
                        <p className="date">
                          {i + 1}ª entrega -{' '}
                          {new Date(delivery).toLocaleDateString()}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="label">
                  <p className="title">Total da compra:</p>
                  <b>{formatValue(orderTotalValue)}</b>
                </div>
              </Freight>
            </div>
          </FreightContent>

          <Button color="secondary" onClick={handleContinue}>
            Confirmar
          </Button>
        </div>
      </Content>

      <Modal
        visible={togglePreIdentification}
        img={NotebookGirlImg}
        onClose={handleTogglePreIdentificationModal}>
        <PreIdentificationModal
          forceClose={handleTogglePreIdentificationModal}
        />
      </Modal>
    </Container>
  )
}

export default Revision
