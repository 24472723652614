import styled, { css } from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

interface CardProductsProps {
  type?: 'radio' | 'checkbox'
  marked?: boolean
}

interface ProductImgProps {
  src: string
}

export const Container = styled.div<CardProductsProps>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 8rem 1.5rem 6rem;
  margin-bottom: 1.5rem;

  border: 2px solid #e5e5df;
  border-radius: 8px;

  div {
    b {
      color: ${colors.grayDark};
      margin-right: 0.5rem;
    }

    div {
      display: flex;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      p {
        font-weight: 400;
        color: ${colors.gray};

        & + p {
          margin-left: 1rem;
        }
      }
    }
  }

  span {
    b {
      font-size: ${sizes.xl};
      font-weight: 700;
      color: ${colors.primaryDark};
    }

    p {
      margin-top: 0.2rem;
      font-size: ${sizes.sm};
      color: ${colors.gray};
    }
  }

  .button_details {
    border: none;
    background: none;
    text-decoration: underline;
    color: ${colors.grayDark};
    cursor: pointer;

    svg {
      margin-left: 0.5rem;
      stroke: ${colors.grayDark};
      transform: translateY(3px);
    }
  }

  /* RADIO and CHECKBOX */
  button:last-child {
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translate(-50%, -50%);
    color: #fff;

    svg {
      display: none;
    }

    ${({ type, marked }) => {
      switch (type) {
        case 'checkbox':
          return css`
            width: 1rem;
            height: 1rem;
            padding: 0;
            border-radius: 4px;

            svg {
              display: block;
              stroke-width: 4px;
            }

            ${marked &&
            css`
              color: ${colors.secondary};
            `}
          `
        default:
          return css`
            width: 1rem;
            height: 1rem;
            padding: 0;
            border-radius: 50%;

            ${marked &&
            css`
              &:before {
                content: '';
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                border: none;
                background: ${colors.secondary};
                margin: 2px;
              }
            `}
          `
      }
    }}
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 1rem 4rem 1rem 7rem;

    button:last-child {
      right: 1rem;
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem 1.2rem 1rem 8rem;

    justify-content: start;
    align-items: center;
    gap: 0.75rem;
    font-size: ${sizes.sm};

    img {
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }

    div {
      b {
        color: ${colors.grayDark};
        font-weight: 500;
        font-size: 1rem;
      }
      > div {
        flex-direction: column;
        gap: 4px;
        margin-bottom: 4px;

        p {
          & + p {
            margin-left: 0rem;
          }
        }
      }
    }

    span {
      > b {
        font-size: ${sizes.lg};
      }
    }

    .button_details {
      font-size: ${sizes.sm};
    }

    button:last-child {
      top: 1rem;
      right: 0;
    }
  }

  @media (max-width: ${responsives.smallDevices}) {
    padding: 1rem;
    img {
      margin: 0 auto;
      position: relative;
      width: 80px;
    }
  }
`

export const Details = styled.div`
  max-height: 600px;
  overflow: hidden;
  padding: 2rem 1rem;
  color: ${colors.grayDark};

  > h3 {
    font-size: ${sizes.xl};
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  > p {
    margin-bottom: 1rem;
  }

  .scroll {
    height: calc(100% - 80px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 8px;
      height: 100%;
    }

    ::-webkit-scrollbar-track {
      background: #f4f5ff;
      border-radius: 4px;
      -webkit-border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.primary};
      border-radius: 4px;
      -webkit-border-radius: 4px;
    }

    .products {
      margin-bottom: 0.5rem;

      span {
        margin-left: 1rem;
      }

      .label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        background: #f4f5ff;
        padding: 6px 8px;
        font-weight: 500;

        span {
          color: ${colors.primaryDark};
        }
      }

      .product {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        color: ${colors.gray};
        padding: 6px 8px;
      }
    }
  }

  @media (min-width: ${responsives.largeDevices}) {
    width: 600px;
  }
`

export const ProductImg = styled.div<ProductImgProps>`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 72px !important;

  border-radius: 4px;

  background: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`
