import styled from 'styled-components'
import { colors, sizes, responsives } from 'styles/styleguide'

interface StepProps {
  complete?: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  padding: 0 1rem;

  @media (max-width: ${responsives.mediumDevices}) {
    width: 100%;
    max-width: 300px;
  }
`

export const Step = styled.div<StepProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: ${sizes.sm};
  font-weight: 500;

  > .circle {
    position: relative;
    width: 3rem;
    height: 3rem;
    border: 4px solid;
    border-radius: 50%;
    margin-bottom: 14px;

    display: grid;
    place-items: center;
    font-size: ${sizes.xl};

    cursor: pointer;

    z-index: 1;

    &:disabled {
      cursor: auto;
    }
  }

  > .line {
    position: absolute;
    transform: translate(-50%, 1.4rem);
    width: 11rem;
    height: 4px;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    font-size: ${sizes.xs};

    > .circle {
      width: 1.5rem;
      height: 1.5rem;

      border: 2px solid;
      margin-bottom: 4px;

      font-size: ${sizes.sm};
    }

    > .line {
      position: absolute;
      transform: translate(-50%, 0.7rem);
      width: 5.5rem;
      height: 2px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  ${(props) => {
    return props.complete
      ? `
      color: #755ffb;
      > .circle {
        box-shadow: 0px 4px 16px -5px #6c63ff;
        border-width: 2px;
        border-color: ${colors.secondary};
        background: linear-gradient(180deg, #755ffb 42.71%, #372ec7 100%);;
        color: #fffffe;
      }

      > .line {
        background: #755ffb;
      }
      `
      : `
        color: ${colors.gray};
        > .circle {
          border-color: #bfbfbf;
          background: #e5e5df;
          color: #c4c4c4;
        }

        > .line {
          background: #ccccc7;
        }
      `
  }}
`
