import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Steps from 'components/Steps'
import Button from 'components/Button'
import Modal from 'components/Modal'
import PreIdentificationModal from 'components/PreIdentificationModal'
import PreIdentification from 'components/PreIdentification'
import LoginModal from 'components/LoginModal'
import Collection from 'components/Collection'

import { useAlert } from 'hooks/alert'
import { useAuth } from 'hooks/auth'
import { useOrder } from 'hooks/order'

import { formatValue } from 'utils/formatValue'

import NotebookGirlImg from 'assets/pngs/pexels-andrea-piacquadio.png'

import _ from 'lodash'
import { ActionsFooter, Container, Content, Decision } from './styles'

const Materials: React.FC = () => {
  const [togglePreIdentification, setTogglePreIdentification] = useState(false)
  const [toggleDecisionModal, setToggleDecisionModal] = useState(false)
  const [toggleSigninModal, setToggleSigninModal] = useState(false)

  const { createModal } = useAlert()
  const { user, isAuthenticated } = useAuth()
  const {
    cart,
    cartTotalValue,
    preIdentification,
    principalCollection,
    itineraryCollection,
    extraCollection,
  } = useOrder()

  const { push } = useHistory()

  const handleTogglePreIdentificationModal = useCallback(() => {
    setTogglePreIdentification((state) => !state)
  }, [])

  const handleToggleRegister = useCallback(() => {
    setToggleDecisionModal(false)

    redirectIdentification()
  }, [])

  const handleToggleDecisionModal = useCallback(() => {
    if (!_.isEmpty(cart)) {
      if (isAuthenticated()) {
        redirectIdentification()
      } else {
        setToggleDecisionModal((state) => !state)
      }
    } else {
      createModal({
        title: 'Nenhum material didático selecionado!',
        description:
          'Entre em contato com a sua escola, material ainda não disponível para compra.',
      })
    }
  }, [cart, user])

  const handleToggleSigninModal = useCallback(() => {
    setToggleSigninModal((state) => !state)
  }, [])

  const redirectIdentification = useCallback(() => {
    push('/identification')
  }, [])

  useEffect(() => {
    if (toggleDecisionModal && isAuthenticated()) redirectIdentification()
  }, [user])

  useEffect(() => {
    if (_.isEmpty(preIdentification)) push('/')
  }, [])

  return (
    <Container>
      <Content>
        <Steps current={1} />

        <div className="content-preIdentification">
          <PreIdentification handleEdit={handleTogglePreIdentificationModal} />
        </div>

        {_.isEmpty(principalCollection) &&
          _.isEmpty(itineraryCollection) &&
          _.isEmpty(extraCollection) && (
            <>
              <h3>Nenhum material didático encontrado!</h3>
            </>
          )}

        {!_.isEmpty(principalCollection) && (
          <Collection
            collection={principalCollection}
            collectionTitle="Principal - Coleção obrigatória"
            collectionDescription="Coleção obrigatória."
          />
        )}

        {!_.isEmpty(itineraryCollection) && (
          <Collection
            collection={itineraryCollection}
            collectionTitle="Itinerários Formativos"
            collectionDescription="Selecione a área de conhecimento escolhida pelo aluno"
          />
        )}

        {!_.isEmpty(extraCollection) && (
          <Collection
            collection={extraCollection}
            collectionTitle="Materiais adicionais"
            collectionDescription="Selecione o material didático para incluí-lo no carrinho"
          />
        )}

        <ActionsFooter>
          <span>
            <p>Valor total dos produtos</p>
            <b>{formatValue(cartTotalValue)}</b>
          </span>
          <Button color="secondary" onClick={handleToggleDecisionModal}>
            Continuar
          </Button>
        </ActionsFooter>
      </Content>

      <Modal
        visible={togglePreIdentification}
        img={NotebookGirlImg}
        onClose={handleTogglePreIdentificationModal}>
        <PreIdentificationModal
          forceClose={handleTogglePreIdentificationModal}
        />
      </Modal>

      <Modal
        visible={toggleDecisionModal}
        img={NotebookGirlImg}
        onClose={handleToggleDecisionModal}>
        <Decision>
          <h1>Quase lá!</h1>
          <p>Agora precisamos de alguns dados para continuar</p>

          <div className="button_container">
            <Button
              type="button"
              color="secondary"
              onClick={handleToggleSigninModal}>
              Entrar
            </Button>

            <Button type="button" onClick={handleToggleRegister}>
              Criar cadastro
            </Button>
          </div>
        </Decision>
      </Modal>

      <LoginModal
        visible={toggleSigninModal}
        onClose={handleToggleSigninModal}
      />
    </Container>
  )
}

export default Materials
