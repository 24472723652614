import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from 'services/api'
import { Form } from '@unform/web'
import { useAuth } from 'hooks/auth'
import { Link } from 'react-router-dom'

import Input from 'components/Form/Input'
import Button from 'components/Button'

import { formatValue } from 'utils/formatValue'

import { FiSearch, FiArrowRight } from 'react-icons/fi'
import KitIlustration from 'assets/pngs/kit.png'
import { ListOrderContainer } from '../styles'

interface ListOrdersProps {
  ordersQuantity: number
}

export interface OrderProductsProps {
  name: string
  description: string
  codeProduct: string
  price: number
}

export interface OrderAreasProps {
  orderProducts: OrderProductsProps[]
}

export interface OrderAreaTypesProps {
  orderAreas: OrderAreasProps[]
}

export interface OrderProps {
  id: number
  status: string
  school: { name: string }
  segment: { name: string }
  serie: { name: string }
  student: { name: string }
  customer: { name: string }
  price: number
  paymentMethod: string
  installments: number
  paymentDate: string
  voucher: { code: string }
  createdAt: string
  orderAreaTypes: OrderAreaTypesProps[]
  // previsao de entrega
  // endereço de entrega

  toString: string
}

const ListOrders: React.FC<ListOrdersProps> = ({
  ordersQuantity,
}: ListOrdersProps) => {
  const [filter, setFilter] = useState('')
  const [orders, setOrders] = useState([] as OrderProps[])

  const { user } = useAuth()

  const filteredOrders = useMemo(() => {
    const filtersLower = filter.toLowerCase().trim().split(' ')

    return orders.filter((order) => {
      let hasMatch = false

      filtersLower
        .filter((str, i) => str !== '' || i === 0)
        .forEach((str) => {
          if (order.toString.indexOf(str) >= 0) hasMatch = true
        })

      return hasMatch
    })
  }, [orders, filter])

  const handleSubmitSearch = useCallback((data) => {
    setFilter(data.search)
  }, [])

  const handleChangeSearch = useCallback((e) => {
    const { value } = e.target
    setFilter(value)
  }, [])

  const toStringOrder = (order: OrderProps) => {
    const str = `${order.id}
    ${order.student.name}
    ${order.segment.name}
    ${order.serie.name}
    ${order.status}
    ${new Date(order.createdAt).toLocaleDateString()}`.toLowerCase()

    return str
  }

  const getListOrders = useCallback(async () => {
    try {
      const { data } = await api.get(`api/orders?customer=${user.id}`)

      const dataFormated = data.map((order: OrderProps) => ({
        ...order,
        toString: toStringOrder(order),
      }))

      setOrders(dataFormated.sort(compareOrder))
    } catch (err) {
      if (err instanceof Error) console.error('err non prev', err.message)
    }
  }, [user])

  const compareOrder = (a: OrderProps, b: OrderProps) => {
    const [aCreatedAt, bCreatedAt] = [
      new Date(a.createdAt),
      new Date(b.createdAt),
    ]

    if (aCreatedAt < bCreatedAt) return 1
    if (aCreatedAt > bCreatedAt) return -1

    return 0
  }

  const quantityOrderItems = ({ orderAreaTypes }: OrderProps) => {
    return orderAreaTypes.reduce((total, type) => {
      return (
        total +
        type.orderAreas.reduce((sum, area) => {
          return sum + area.orderProducts.length
        }, 0)
      )
    }, 0)
  }

  useEffect(() => {
    getListOrders()
  }, [getListOrders])

  return (
    <ListOrderContainer>
      <Form onSubmit={handleSubmitSearch}>
        <Input
          name="search"
          placeholder="Buscar por número do pedido, nome do aluno, data, segmento ou série."
          onChange={handleChangeSearch}
        />
        <Button>
          <FiSearch size={20} />
        </Button>
      </Form>

      <p>
        Exibindo <b>{filteredOrders.length} resultados</b> de {ordersQuantity}
      </p>

      {filteredOrders.map((order) => (
        <div key={order.id} className="order_cart">
          <img src={KitIlustration} alt="ilustração do kit" />

          <div>
            <div className="status">
              <p>Pedido: {order.id}</p>
              <p>
                Data do pedido: {new Date(order.createdAt).toLocaleDateString()}
              </p>
              <p>Status: {order.status}</p>

              <Link
                className="button_details"
                to={{
                  pathname: `/orders/${order.id}`,
                  state: order,
                }}>
                Detalhes do pedido
                <FiArrowRight />
              </Link>
            </div>

            <div className="info">
              <div>
                <p>{order.serie.name}</p>
                <b>{order.segment.name}</b>
              </div>

              <div>
                <p>Nome do aluno</p>
                <b>{order.student.name}</b>
              </div>

              <span>
                <b>{formatValue(order.price)}</b>
                <p>Contém {quantityOrderItems(order)} itens.</p>
              </span>
            </div>

            <Link
              className="button_details"
              to={{
                pathname: `/orders/${order.id}`,
                state: order,
              }}>
              Detalhes do pedido
              <FiArrowRight />
            </Link>
          </div>
        </div>
      ))}
    </ListOrderContainer>
  )
}

export default ListOrders
