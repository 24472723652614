import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import { FormHandles, Scope } from '@unform/core'
import axios from 'axios'
import api from 'services/api'

import { useAlert } from 'hooks/alert'
import { useAuth } from 'hooks/auth'
import { useTerm } from 'hooks/term'

import Button from 'components/Button'
import Input from 'components/Form/Input'
import Radio from 'components/Form/Radio'
import Checkbox from 'components/Form/Checkbox'

import { getValidationErrors, validationErrorsArray } from 'utils/validation'
import Company from './Company'
import Person from './Person'

import { Container, FormContainer } from './styles'

interface RegisterAddressProps {
  postalCode: string
  street: string
  city: string
  state: string
  neighborhood: string
  number: number
  complement: string
}

interface RegisterTerms {
  [key: string]: boolean
}

export interface UserFormProps {
  name: string
  email: string
  documentType: 'CPF' | 'CNPJ'
  cpf: string
  uf: string
  companyName: string
  cnpj: string
  rg: string
  phone: string
  password: string
  emailConfirmation: string
  passwordConfirmation: string
  address: RegisterAddressProps
  birthDate: string
  openingDate: string
  hasStateRegistration: string
  stateRegistration: string | undefined
  municipalRegistration: string
  terms: RegisterTerms
}

const RegisterForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { signIn } = useAuth()
  const { createModal } = useAlert()
  const { handleToggleModalTerm, terms, termToId } = useTerm()
  const { push } = useHistory()

  const [registerFormType, setRegisterFormType] = useState<'CPF' | 'CNPJ'>(
    'CPF',
  )
  const [invalidTerms, setInvalidTerms] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const formatDate = (str: string): string => {
    const [d, m, y] = str.split('/')

    const parseDate = Date.parse(`${y}/${m}/${d}`) || 0
    const dateTimeFormat = new Intl.DateTimeFormat('pt-BR')

    if (dateTimeFormat.format(parseDate) === str) return `${y}/${m}/${d}`

    return ''
  }

  const signUp = useCallback(
    async (data: UserFormProps) => {
      try {
        const termAccepteds = Object.entries(data.terms)
          .map(([term, accepted]) =>
            accepted ? { term: `/api/terms/${termToId(term).id}` } : undefined,
          )
          .filter((term) => term !== undefined)

        const payload = {
          ...data,
          usertype: '/api/user_types/3',
          addresses: [data.address],
          termAccepteds,
          vouchers: [],
          status: true,
        }

        const { status } = await api.post('/api/users', payload)

        if (status === 201) {
          const authParams = {
            email: data.email,
            password: data.password,
          }

          await signIn(authParams)

          window.scrollTo(0, 0)
          formRef.current?.reset()
          push('/revision')
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMesssage = err.response?.data?.violations[0]?.message

          throw new Error(errorMesssage || 'Tente novamente mais tarde!')
        } else {
          console.log(err)
        }
      }
    },
    [terms],
  )

  const handleSubmit = useCallback(
    async (submitPayload: UserFormProps) => {
      try {
        setIsLoading(true)
        let registerPayload = {
          documentType: submitPayload?.documentType,
          name: submitPayload?.name,
          phone: submitPayload?.phone.replace(/\D/g, ''),
          email: submitPayload?.email,
          emailConfirmation: submitPayload?.emailConfirmation,
          password: submitPayload?.password,
          passwordConfirmation: submitPayload?.passwordConfirmation,
          address: {
            postalCode: submitPayload?.address.postalCode.replace('-', ''),
            street: submitPayload?.address.street,
            city: submitPayload?.address.city,
            state: submitPayload?.address.state,
            neighborhood: submitPayload?.address.neighborhood,
            number: submitPayload?.address.number,
            complement: submitPayload?.address.complement,
          },
          terms: {
            ...submitPayload.terms,
          },
        } as UserFormProps

        if (registerPayload.email) {
          const emailDomain = registerPayload.email.split('@')[1].toLowerCase()

          if (
            emailDomain === 'bernoulli.com.br' ||
            emailDomain === 'colegiobernoulli.com.br' ||
            emailDomain === 'portalmodulo.com.br'
          ) {
            createModal({
              title: 'Erro com domínio de e-mail',
              description:
                'Utilize seu e-mail pessoal para continuar. Os seguintes domínios não são aceitos: ',
            })

            return
          }
        }

        if (
          formRef.current?.getFieldError('address.postalCode') ===
          'CEP inválido'
        ) {
          createModal({
            title: 'CEP inválido',
            description: 'O CEP digitado é inválido',
          })

          return
        }

        formRef.current?.setErrors({})

        if (submitPayload.documentType === 'CPF') {
          const registerSchema = Yup.object().shape({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            rg: Yup.string().required(),
            cpf: Yup.string().required(),
            uf: Yup.string().required(),
            phone: Yup.string().min(10).max(11).required(),
            password: Yup.string()
              .required()
              .matches(/^(?=.*[a-z,A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
            birthDate: Yup.date()
              .min(new Date('1900'))
              .max(new Date())
              .required(),
            emailConfirmation: Yup.string()
              .email()
              .oneOf([Yup.ref('email'), null], 'E-mais devem ser iguais'),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Senhas devem ser iguais',
            ),
            address: Yup.object().shape({
              postalCode: Yup.string().min(8).required(),
              street: Yup.string().required(),
              city: Yup.string().required(),
              state: Yup.string().required(),
              neighborhood: Yup.string().required(),
              number: Yup.number().required(),
              complement: Yup.string(),
            }),
            terms: Yup.object().shape(
              Object.fromEntries(
                terms.map((term) => {
                  return term.isActive
                    ? [
                        term.title,
                        term.isRequired
                          ? Yup.boolean().oneOf([true])
                          : Yup.boolean(),
                      ]
                    : []
                }),
              ),
            ),
          })

          registerPayload = {
            ...registerPayload,
            rg: submitPayload?.rg.replace(/\D/g, ''),
            cpf: submitPayload.cpf.replace(/\D/g, ''),
            birthDate: formatDate(submitPayload.birthDate),
            uf: submitPayload.uf,
          }

          await registerSchema.validate(registerPayload, { abortEarly: false })
        } else {
          const registerSchema = Yup.object().shape({
            name: Yup.string().required(),
            companyName: Yup.string().required(),
            email: Yup.string().email().required(),
            cnpj: Yup.string().required(),
            hasStateRegistration: Yup.string().required(),
            stateRegistration: Yup.string().when('hasStateRegistration', {
              is: 'Sim',
              then: Yup.string().required(),
              otherwise: Yup.string().notRequired(),
            }),
            municipalRegistration: Yup.string().required(),
            phone: Yup.string().min(10).max(11).required(),
            password: Yup.string()
              .required()
              .matches(/^(?=.*[a-z,A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
            openingDate: Yup.date()
              .min(new Date('1900'))
              .max(new Date())
              .required(),
            emailConfirmation: Yup.string()
              .email()
              .oneOf([Yup.ref('email'), null], 'E-mais devem ser iguais'),
            passwordConfirmation: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Senhas devem ser iguais',
            ),
            address: Yup.object().shape({
              postalCode: Yup.string().required(),
              street: Yup.string().required(),
              city: Yup.string().required(),
              state: Yup.string().required(),
              neighborhood: Yup.string().required(),
              number: Yup.number().required(),
              complement: Yup.string(),
            }),
            terms: Yup.object().shape(
              Object.fromEntries(
                terms.map((term) => {
                  return term.isActive
                    ? [
                        term.title,
                        term.isRequired
                          ? Yup.boolean().oneOf([true])
                          : Yup.boolean(),
                      ]
                    : []
                }),
              ),
            ),
          })

          registerPayload = {
            ...registerPayload,
            companyName: submitPayload?.companyName,
            cnpj: submitPayload.cnpj.replace(/\D/g, ''),
            hasStateRegistration: submitPayload.hasStateRegistration,
            stateRegistration:
              submitPayload.stateRegistration
                ?.replaceAll('.', '')
                .replaceAll('-', '')
                .replaceAll('/', '') || undefined,
            municipalRegistration: submitPayload.municipalRegistration
              .replaceAll('.', '')
              .replaceAll('-', '')
              .replaceAll('/', ''),
            openingDate: formatDate(submitPayload.openingDate),
          }

          await registerSchema.validate(registerPayload, { abortEarly: false })
        }

        setInvalidTerms(false)
        await signUp(registerPayload)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err)

          formRef.current?.setErrors(validationErrors)

          setInvalidTerms(
            validationErrorsArray(
              ...terms.map((term) =>
                term.isActive ? validationErrors[`terms.${term.title}`] : '',
              ),
            ),
          )
        } else if (err instanceof Error) {
          createModal({ title: 'Erro ao cadastrar', description: err.message })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [signUp],
  )

  const handleRadio = useCallback((data) => {
    formRef.current?.reset()
    formRef.current?.setErrors({})
    setInvalidTerms(false)
    setRegisterFormType(data.target.value)
  }, [])

  const setField = (name: string, value: string) => {
    formRef.current?.setFieldValue(name, value)
    formRef.current?.setFieldError(name, '')
  }

  const handleChangePostalCode = useCallback(async (e) => {
    const { value } = e.target

    if (value.length === 9) {
      try {
        const { data } = await api.post(`/api/address/by-cep`, {
          cep: String(value).replace('-', ''),
        })

        setField('address.state', data.state)
        setField('address.city', data.city)
        setField('address.neighborhood', data.neighborhood)
        setField('address.street', String(data.street).split('-')[0])

        formRef.current?.setFieldError('address.postalCode', '')
      } catch (err) {
        formRef.current?.setFieldError('address.postalCode', 'CEP inválido')
        setField('address.state', '')
        setField('address.city', '')
        setField('address.neighborhood', '')
        setField('address.street', '')
      }
    }
  }, [])

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          documentType: 'CPF',
        }}>
        <div className="radio_container">
          <Radio
            name="documentType"
            onChange={handleRadio}
            options={[
              { id: 'CPF', label: 'Pessoa física' },
              { id: 'CNPJ', label: 'Pessoa jurídica' },
            ]}
          />
        </div>

        <div className="main_form">
          <div className="form">
            {registerFormType === 'CPF' ? (
              <Person formRef={formRef} />
            ) : (
              <Company formRef={formRef} />
            )}

            <FormContainer>
              <Scope path="address">
                <span>
                  Endereço para faturamento da nota fiscal e envio do material
                  didático:
                </span>

                <strong>CEP:</strong>
                <Input
                  name="postalCode"
                  mask="99999-999"
                  onBlur={handleChangePostalCode}
                  placeholder="00000-000"
                />
                <div>
                  <a
                    className="cep_info"
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="blank">
                    Não sei meu CEP
                  </a>
                </div>

                <strong>Estado:</strong>
                <Input name="state" placeholder="Insira um CEP" disabled />

                <strong>Cidade:</strong>
                <Input name="city" placeholder="Insira um CEP" disabled />

                <strong>Bairro:</strong>
                <Input name="neighborhood" />

                <strong>Rua:</strong>
                <Input name="street" />

                <strong>Número:</strong>
                <Input name="number" />

                <strong>Complemento (opcional):</strong>
                <Input name="complement" />
              </Scope>
            </FormContainer>
          </div>

          <div className="terms_container">
            <Scope path="terms">
              {terms.map(
                (term) =>
                  term.isActive && (
                    <Checkbox
                      key={term.id}
                      name={term.title}
                      value={term.title}>
                      <span>
                        {'Li e aceito '}
                        <button
                          type="button"
                          onClick={() => handleToggleModalTerm(term)}>
                          {term.title}
                          {term.isRequired && '*'}
                        </button>
                      </span>
                    </Checkbox>
                  ),
              )}

              {invalidTerms && (
                <span className="error">Verifique os termos obrigatórios*</span>
              )}
            </Scope>
          </div>

          <Button
            isLoading={isLoading}
            type="submit"
            className="submit_button"
            color="secondary">
            Criar cadastro
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default RegisterForm
