import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'

import { FiChevronDown } from 'react-icons/fi'
import { Container } from './styles'

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  children: React.ReactNode
}

const Select: React.FC<SelectProps> = ({ name, children, ...rest }) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef,
      getValue: (ref) => ref.current?.value,
      /* eslint-disable no-param-reassign */
      setValue: (ref, newValue) => {
        ref.current.value = newValue
      },
      /* eslint-disable no-param-reassign */
      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error}>
      <select
        id={fieldName}
        ref={selectRef}
        defaultValue={defaultValue}
        onFocus={clearError}
        {...rest}>
        {children}
      </select>
      <FiChevronDown size={20} />
    </Container>
  )
}

export default Select
