import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

export const Container = styled.div`
  max-width: 1067px;

  display: flex;
  flex-flow: column;
  align-items: center;

  margin: 3rem auto;

  > form {
    width: 100%;

    padding: 1rem;
    margin-top: 2rem;

    border: 2px solid #cfd1ff;
    border-radius: 8px;

    > .radio_container {
      display: flex;

      > div {
        > label + label {
          margin-left: 1rem;
        }
      }
    }

    > .main_form {
      > .form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      > .terms_container {
        display: flex;
        flex-flow: column;
        width: calc(75% / 2);

        > div {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          > input {
            width: 24px;
            height: 24px;
          }

          > span {
            margin-left: 1rem;
            width: 100%;
            color: ${colors.grayDark};

            > a,
            button {
              background: transparent;
              color: ${colors.grayDark};

              text-decoration: underline;
              border: 0;

              cursor: pointer;
            }
          }
        }

        > .error {
          margin-top: 1rem;
          color: ${colors.error};
        }
      }

      > .submit_button {
        margin-top: 2rem;
        width: calc(75% / 2);
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > form {
      .main_form {
        .form {
          display: flex;
          flex-flow: column;
        }

        .terms_container {
          width: 100%;
          display: flex;
          margin-top: 1rem;

          > div {
            display: flex;

            > span {
              font-size: ${sizes.sm};
              margin-left: 1rem;

              > button {
                font-size: ${sizes.sm};
              }
            }
          }

          > .error {
            font-size: ${sizes.sm};
          }
        }

        > .submit_button {
          width: 100%;
        }
      }
    }
  }
`

export const FormContainer = styled.div`
  > span {
    display: flex;
    width: 75%;

    padding: 1rem;
    border-radius: 8px;

    font-weight: 500;
    color: ${colors.primaryDark};
    background-color: ${colors.primaryLight};
  }

  > strong {
    display: block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: ${sizes.base};
    color: ${colors.grayDark};
  }

  > div {
    display: flex;
    width: 75%;
    height: 40px;
    margin-top: 0.5rem;
  }

  > button {
    width: 75%;
  }

  > div > a {
    width: max-content;
    margin-left: auto;
  }

  > p {
    color: ${colors.primaryDark};
    font-size: ${sizes.sm};
    width: 75%;
    margin-top: 0.5rem;
    line-height: 14.4px;
  }

  > .cep_info {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;

    border: 0;
    background: transparent;

    color: ${colors.primaryDark};
    font-size: ${sizes.base};
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: ${responsives.largeDevices}) {
    width: 100%;

    > span {
      margin-top: 1rem;
      width: 90%;
    }

    > div {
      width: 90%;
    }

    > p {
      width: 90%;
    }

    > button {
      display: none;
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    width: 100%;

    > span {
      margin-top: 1rem;
      width: 100%;
    }

    > div {
      width: 100%;
    }

    > p {
      width: 100%;
    }

    > button {
      display: none;
    }
  }
`
