import React, { useState, useEffect } from 'react'
import _ from 'lodash'

type UseStateProps<T> = [
  value: T,
  setValue: React.Dispatch<React.SetStateAction<T>>,
]

export function useStorage<T>(key: string, defaultValue: T): UseStateProps<T> {
  const getStorageValue = () => {
    const saved = sessionStorage.getItem(key)
    if (saved) return JSON.parse(saved)
    return defaultValue
  }

  const [value, setValue] = useState(() => {
    return getStorageValue()
  })

  useEffect(() => {
    if (!_.isEmpty(value)) sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
