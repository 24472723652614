import styled from 'styled-components'
import { colors } from 'styles/styleguide'

export const Container = styled.div`
  display: flex;
  flex-flow: row;

  justify-content: center;
  align-items: center;

  > label {
    position: relative;
    overflow: hidden;

    display: flex;
    flex-flow: row;

    align-items: center;

    > input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 20px;
      height: 20px;
      margin-right: 0.4rem;

      border-radius: 50%;
      border: 2px solid ${colors.secondary};

      cursor: pointer;

      &:checked {
        &::before {
          content: '';
          display: flex;

          width: 10px;
          height: 10px;

          margin-top: 3px;
          margin-left: 3px;

          border-radius: 50%;
          background: ${colors.secondary};
        }
      }

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    > span {
      color: ${colors.gray};
      font-weight: 400;
    }
  }
`
