import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

export const Content = styled.div`
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3,
  .school {
    color: ${colors.grayDark};
    font-weight: 600;
  }

  > h3 {
    font-size: ${sizes.xl};
  }

  p {
    font-size: ${sizes.base};
    color: ${colors.gray};
    margin-top: 0.6rem;
  }

  form {
    width: 100%;
    display: grid;

    .school {
      font-size: ${sizes.lg};
      margin-top: 1.5rem;
    }

    .segmentSerie {
      margin-top: 0;
    }

    .student {
      margin-top: 2rem;
    }

    label {
      font-size: ${sizes.base};
      font-weight: 500;
      color: ${colors.gray};
      margin-bottom: 0.5rem;
    }

    .selects {
      display: flex;

      > div {
        display: grid;
      }
    }

    button {
      height: 40px;
      margin-top: 1rem;
    }
  }

  @media (min-width: ${responsives.largeDevices}) {
    padding: 3rem;
    align-items: normal;

    form {
      label {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }

      .selects {
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
      }

      button {
        margin-top: 3rem;
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    form {
      > label {
        margin-top: 1rem;
      }
    }
  }
`

export const HiddenInput = styled.div`
  div {
    border: none;
    padding: 0;
    display: none;
    input {
      display: none;
    }
  }
`
