import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import { Form } from '@unform/web'

export const Container = styled(Form)`
  padding: 2rem;
  margin-top: 1rem;

  width: 450px;

  flex-direction: row;
  align-items: center;

  > div {
    margin-top: 0.5rem;
  }

  h3 {
    color: ${colors.grayDark};
    margin-bottom: 1rem;
  }

  input {
    margin-top: 1rem;
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 1rem;
    width: 100%;
    color: ${colors.grayDark};

    > a,
    button {
      background: transparent;
      color: ${colors.grayDark};

      text-decoration: underline;
      border: 0;

      cursor: pointer;
    }
  }

  > button {
    width: 100%;
    margin-top: 1rem;
    color: ${colors.secondary};
  }

  .error {
    display: flex;
    margin-top: 0.5rem;
    color: ${colors.error};
  }

  @media (max-width: ${responsives.largeDevices}) {
    width: 340px;

    .error {
      font-size: ${sizes.sm};
    }
  }
`
