import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

export const Container = styled.div`
  width: 100%;
  padding: 0 3rem;

  > div {
    position: relative;
    width: 100%;
    max-width: 1067px;
    margin: 1.5rem auto;
    padding: 2rem 2rem 0;
    border-radius: 8px;
    background: #fffffe;

    border: 2px solid ${colors.primaryLight};

    > div:first-child {
      width: 100%;
      display: flex;
      margin-bottom: 3rem;

      > div {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-size: 1rem;
          font-weigth: 500;
          color: ${colors.grayDark};
        }

        p {
          font-size: ${sizes.sm};
          color: ${colors.gray};
        }
      }
    }

    @media (max-width: ${responsives.mediumDevices}) {
      padding: 1rem;
      border: none;
      border-radius: 0;

      > div:first-child {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 0;
  }
`
