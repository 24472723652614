import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fffffe.png'

export const Container = styled.div`
  widht: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-position: top -840px right -580px;
  background-repeat: no-repeat;

  @media (max-width: ${responsives.largeDevices}) {
    padding: 2rem 1rem 3.5rem 1rem;
  }

  .loading {
    width: max-content;
    margin: 2rem auto;
    width: 3rem;
    height: 3rem;
    border: 6px solid ${colors.grayLight};
    border-top: 6px solid ${colors.primary};
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1067px;

  > h2 {
    font-size: ${sizes.h1};
    font-weight: 700;
    color: ${colors.grayDark};
    margin-bottom: 0.5rem;
  }

  > p {
    color: ${colors.gray};
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > h2 {
      font-size: ${sizes.h3};
    }
  }
`

export const NoOrdersContainer = styled.div`
  margin: 5rem auto;

  text-align: center;

  > h3 {
    font-size: ${sizes.lg};
    font-weight: 700;
    color: ${colors.grayDark};
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  > p {
    color: ${colors.gray};
    margin-bottom: 1.5rem;
  }

  > button {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    margin: 2rem auto;

    > img {
      width: 160px;
    }

    > p {
      margin-bottom: 4rem;
    }
  }
`

export const ListOrderContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  > form {
    position: relative;

    > div {
      background: #fff;
      padding-right: 2.3rem;

      input {
        text-overflow: ellipsis;
      }
    }

    > button {
      position: absolute;
      top: 0rem;
      right: 0rem;

      border: none;
      color: #7b8794;
    }
  }

  > p {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    color: ${colors.gray};

    b {
      color: ${colors.grayDark};
    }
  }

  > .order_cart {
    position: relative;
    width: 100%;
    display: flex;
    color: #81817d;

    background: #fff;

    border: 2px solid #cfd1ff;
    box-shadow: 0px 13px 18px -1px rgba(18, 18, 18, 0.15);
    border-radius: 8px;

    padding: 1rem;

    > img {
      height: 130px;
      margin-right: 1rem;
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;

      > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        padding: 0.8rem;

        p:nth-child(3) {
          margin-left: 1rem;
        }

        b {
          color: ${colors.grayDark};
        }

        .button_details {
          border: none;
          background: none;
          text-decoration: underline;
          color: ${colors.grayDark};
          cursor: pointer;

          svg {
            margin-left: 0.5rem;
            stroke: ${colors.grayDark};
            transform: translateY(3px);
          }
        }

        span {
          grid-column: 4;

          b {
            font-size: ${sizes.xl};
            font-weight: 700;
            color: ${colors.primaryDark};
          }

          p {
            margin-top: 0.2rem;
            font-size: ${sizes.sm};
            color: ${colors.gray};
          }
        }

        span,
        .button_details {
          text-align: right;
        }
      }

      .button_details {
        border: none;
        background: none;
        text-decoration: underline;
        color: ${colors.grayDark};
        cursor: pointer;

        svg {
          margin-left: 0.5rem;
          stroke: ${colors.grayDark};
          transform: translateY(3px);
        }
      }

      > .button_details {
        display: none;
      }

      > div + div {
        border-top: 1px solid #e5e5df;
      }
    }
  }

  > div + div {
    margin-top: 1rem;
  }

  @media (max-width: ${responsives.largeDevices}) {
    > .order_cart {
      > img {
        position: absolute;
        height: 110px;
      }

      > div {
        > .status {
          margin-left: 5.2rem;
          grid-template-columns: 1fr;

          p:nth-child(3) {
            margin-left: 0;
          }

          .button_details {
            display: none;
          }
        }

        > .info {
          grid-template-columns: repeat(2, auto);
          border-bottom: 1px solid #e5e5df;

          span {
            grid-column: 2;
            grid-row: 1;
          }
        }

        > .button_details:last-child {
          display: block;

          width: 100%;
          margin-top: 1rem;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > .order_cart {
      > div {
        font-size: 14px;

        > .info > span > b {
          font-size: ${sizes.lg};
        }
      }

      .button_details {
        font-size: 14px;
      }
    }
  }
`

export const DetailsContainer = styled(Container)`
  margin-bottom: 1rem;

  > div > span {
    width: 24px;
    height: 24px;

    padding: 0.1rem;
    border-radius: 50%;
    background: ${colors.secondary};

    svg {
      color: #fff;
      position: absolute;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > .not-found {
    display: flex;
    gap: 1rem;
    color: ${colors.grayDark};
    font-size: ${sizes.h3};
  }

  > .box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1067px;

    color: ${colors.grayDark};

    > span {
      position: absolute;
      top: 4px;
    }

    > h3 {
      margin-left: 2rem;
      margin-bottom: 3rem;
      font-size: ${sizes.h3};
    }

    p {
      color: ${colors.gray};
    }

    b {
      font-weight: 500;
    }

    strong {
      font-weight: 700;
    }

    > div {
      width: 100%;

      background: #fff;
      border: 1px solid #cfd1ff;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      > div {
        padding: 1.2rem 2rem;
      }

      > div + div {
        border-top: 1px solid #cfd1ff;
      }

      > .status {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > p {
          margin-rigth: auto;
        }

        > div {
          display: flex;
          gap: 1.5rem;

          > button {
            width: 200px;
          }
        }
      }

      > .info {
        display: grid;
        grid-template-columns: 1fr 1fr;

        padding-top: 2rem;

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          > div {
            display: grid;
            grid-template-columns: auto auto;
            gap: 1rem;

            p {
              margin-top: 0.5rem;
            }
          }
        }
      }

      > .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: red;

        div {
          width: calc(100% + 1rem);
          padding: 0.8rem 0.5rem;
        }

        div:nth-child(2n) {
          background: #f4f5ff;
        }
      }

      > span {
        display: flex;
        flex-flow: row nowrap;
        padding: 1.5rem;
        margin: 2rem 4rem;
        border-radius: 8px;

        font-weight: 500;
        color: ${colors.primaryDark};
        background-color: ${colors.primaryLight};

        > p {
          font-weight: 500;
          color: ${colors.primaryDark};

          margin-left: 1rem;
        }
      }
    }
  }

  > button {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    display: none;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    .box {
      display: flex !important;
      flex-flow: column !important;

      .status {
        display: none !important;
      }

      .info {
        display: flex !important;
        flex-flow: column !important;

        div {
          b {
            margin-top: 1rem;
          }

          div {
            display: flex !important;
            flex-flow: column !important;
          }
        }
      }

      > div {
        span {
          display: flex;
          flex-flow: column;

          justify-content: center;
          align-items: center;

          padding: 1rem;
          margin: 1rem;

          color: ${colors.grayDark};
          font-weight: bold;
          background-color: transparent;

          > p {
            color: ${colors.grayDark};
            font-weight: 400;
          }
        }
      }
    }

    > button {
      display: flex;
    }
  }
`
