import React from 'react'
import { FiX } from 'react-icons/fi'
import { colors } from 'styles/styleguide'

import { Container, Modal as DivModal, Overlay } from './styles'

interface ModalProps {
  visible: boolean
  onClose?: () => void
  children?: React.ReactNode
  img?: string
}

const Modal: React.FC<ModalProps> = ({ visible, children, onClose, img }) => {
  const onClick = () => {
    if (onClose) onClose()
  }

  return (
    <Container isVisible={visible} className="modal">
      <DivModal>
        {onClick === null && (
          <div className="circle">
            <FiX size={24} color={colors.primary} onClick={onClick} />
          </div>
        )}

        {img && (
          <div className="img-container">
            <img src={img} alt="" />
          </div>
        )}

        {children}
      </DivModal>

      <Overlay
        className="overlay"
        role="button"
        tabIndex={-1}
        onClick={onClick}
        onKeyDown={onClick}
      />
    </Container>
  )
}

export default Modal
