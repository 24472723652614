import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/auth'

import Button from 'components/Button'
import Navbar from 'components/Navbar'
import LoginModal from 'components/LoginModal'

import {
  FiBook,
  FiChevronDown,
  FiChevronUp,
  FiLogOut,
  FiMenu,
  FiUser,
  FiX,
} from 'react-icons/fi'

import userIcon from 'assets/icons/user.png'
import logoImg from 'assets/icons/logo.png'

import { colors } from 'styles/styleguide'
import {
  Container,
  HeaderContainer,
  HamburgerMenu,
  UserDropdown,
} from './styles'

const Header: React.FC = () => {
  const [isMenuActive, setisMenuActive] = useState(false)
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [toggleSigninModal, setToggleSigninModal] = useState(false)

  const { user, signOut, isAuthenticated } = useAuth()
  const location = useLocation()

  const handleSignOut = useCallback(() => {
    signOut()
    handleToggleUserDropdown()
  }, [])

  const handleToggleHamburgerMenu = useCallback(() => {
    setToggleDropdown(false)
    setisMenuActive((currentMenuState) => !currentMenuState)
  }, [])

  const handleToggleUserDropdown = useCallback(() => {
    setisMenuActive(false)
    setToggleDropdown((currentToggleDropdown) => !currentToggleDropdown)
  }, [])

  const handleToggleSigninModal = useCallback(() => {
    setToggleSigninModal((state) => !state)
  }, [])

  useEffect(() => {
    setToggleDropdown(false)
    setisMenuActive(false)
  }, [location])

  return (
    <Container>
      <HeaderContainer>
        <Button
          onClick={handleToggleHamburgerMenu}
          color="transparent"
          className="hamburger_menu">
          {isMenuActive ? <FiX size={32} /> : <FiMenu size={32} />}
        </Button>

        <img src={logoImg} alt="logo_plug_book" />

        <div className="navigation_menu">
          <Navbar />
        </div>

        {isAuthenticated() ? (
          <div className="user_data_container">
            {toggleDropdown ? (
              <FiX size={32} onClick={handleToggleUserDropdown} />
            ) : (
              <FiUser size={32} onClick={handleToggleUserDropdown} />
            )}

            <button type="button" onClick={handleToggleUserDropdown}>
              <span>
                <b>{user?.name}</b>

                {toggleDropdown ? (
                  <FiChevronUp color={colors.primaryLight} size={24} />
                ) : (
                  <FiChevronDown color={colors.primaryLight} size={24} />
                )}
              </span>

              <p>{user?.email}</p>
            </button>
          </div>
        ) : (
          <Button color="secondary" onClick={handleToggleSigninModal}>
            <img src={userIcon} alt="icone_usuario" />
            Entrar
          </Button>
        )}
      </HeaderContainer>

      <HamburgerMenu isActive={isMenuActive}>
        <Navbar />

        {!isAuthenticated() && (
          <Button color="secondary" onClick={handleToggleSigninModal}>
            Fazer Login
          </Button>
        )}
      </HamburgerMenu>

      {toggleDropdown && (
        <UserDropdown>
          <ul>
            <li>
              <FiUser size={20} />
              <Link to="/profile">Meu perfil</Link>
            </li>
            <li>
              <FiBook size={20} />
              <Link to="/orders">Meus pedidos</Link>
            </li>
            <li>
              <FiLogOut size={20} />
              <button type="button" onClick={handleSignOut}>
                Sair
              </button>
            </li>
          </ul>
        </UserDropdown>
      )}

      {toggleSigninModal && (
        <LoginModal
          visible={toggleSigninModal}
          onClose={handleToggleSigninModal}
        />
      )}
    </Container>
  )
}

export default Header
