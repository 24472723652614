import React from 'react'
import Modal from 'components/Modal'

import { Container } from './styles'

interface PrivacyPoliticsProps {
  visible: boolean
  onClose(): void
  customHTML: string
}

const CustomHTMLModal: React.FC<PrivacyPoliticsProps> = ({
  visible,
  onClose,
  customHTML,
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <Container
        dangerouslySetInnerHTML={{
          __html: customHTML,
        }}
      />
    </Modal>
  )
}

export default CustomHTMLModal
