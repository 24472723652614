import React, { ButtonHTMLAttributes } from 'react'

import { Container } from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'transparent'
  outlined?: boolean
  isLoading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  isLoading,
  ...rest
}: ButtonProps) => {
  return (
    <Container isLoading={isLoading} {...rest}>
      {children}
      {isLoading && <div className="loading" />}
    </Container>
  )
}

export default Button
