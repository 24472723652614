import React, { useCallback, useState } from 'react'
import { FormHandles } from '@unform/core'

import Input from 'components/Form/Input'
import Select from 'components/Form/Select'

import { maskTelephone } from 'utils/mask'

import { FormContainer } from '../styles'

interface CompanyFormProps {
  formRef: React.RefObject<FormHandles>
}

const Company: React.FC<CompanyFormProps> = ({ formRef }) => {
  const [isStateSignupEnabled, setisStateSignupEnabled] = useState('Não')

  const handleChangeStateSignup = useCallback(() => {
    const stateRegistration = formRef.current?.getFieldValue(
      'hasStateRegistration',
    )

    setisStateSignupEnabled(stateRegistration)

    if (stateRegistration === 'Não')
      formRef.current?.setFieldValue('stateRegistration', '')
  }, [])

  const handleTelephoneMask = useCallback((data) => {
    const { value } = data.target

    formRef.current?.setFieldValue('phone', maskTelephone(value))
  }, [])

  return (
    <FormContainer>
      <strong>CNPJ:</strong>
      <Input
        name="cnpj"
        mask="99.999.999/9999-99"
        placeholder="00.000.000/0000-00"
      />

      <strong>Nome do responsável:</strong>
      <Input name="name" placeholder="Razão social constará na Nota Fiscal" />

      <strong>Razão Social:</strong>
      <Input
        name="companyName"
        placeholder="Razão social constará na Nota Fiscal"
      />

      <strong>Inscrição Estadual:</strong>
      <Select
        name="hasStateRegistration"
        onChange={handleChangeStateSignup}
        defaultValue={0}>
        <option value={0} disabled hidden>
          Não
        </option>

        <option key={1} value="Sim">
          Sim
        </option>

        <option key={2} value="Não">
          Não
        </option>
      </Select>
      <Input
        name="stateRegistration"
        disabled={isStateSignupEnabled === 'Não'}
      />

      <strong>Inscrição Municipal:</strong>
      <Input name="municipalRegistration" />

      <strong>Data de abertura da empresa :</strong>
      <Input name="openingDate" mask="99/99/9999" placeholder="dd/mm/aaaa" />

      <strong>Telefone:</strong>
      <Input
        name="phone"
        onChange={handleTelephoneMask}
        placeholder="(00) 0000-0000"
      />

      <strong>E-mail:</strong>
      <Input name="email" placeholder="exemplo@exemplo.com.br" />

      <strong>Confirme seu e-mail:</strong>
      <Input name="emailConfirmation" placeholder="exemplo@exemplo.com.br" />

      <strong>Senha:</strong>
      <Input
        name="password"
        type="password"
        placeholder="********"
        isPassword
      />
      <p>
        *A senha deve conter 8 ou mais caracteres com uma combinação de letras,
        números e símbolos
      </p>

      <strong>Confirme sua senha:</strong>
      <Input
        name="passwordConfirmation"
        type="password"
        placeholder="********"
        isPassword
      />
    </FormContainer>
  )
}

export default Company
