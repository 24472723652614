import React, { useEffect, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { useAuth } from 'hooks/auth'

import Button from 'components/Button'

import { FiChevronLeft } from 'react-icons/fi'
import { formatValue } from 'utils/formatValue'
import { DetailsContainer } from 'screens/Orders/styles'

import { OrderProps } from '..'

const Details: React.FC = () => {
  const { getUserData, userData } = useAuth()

  const location = useLocation<OrderProps>()
  const { state } = location
  const { push } = useHistory()

  const hasOrder = useMemo(() => !_.isEmpty(state), [state])

  const cpfCnpj = useMemo(() => {
    if (_.isEmpty(userData)) return ''

    return userData.documentType === 'CPF'
      ? userData.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      : userData.cpf.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        )
  }, [userData])

  const paymentMethod =
    hasOrder && state.paymentMethod === 'credit_card'
      ? 'Cartão de crédito'
      : 'Boleto'

  const handlePushBack = () =>
    push({
      pathname: '/orders',
      state: location,
    })

  const handleHelp = () => {
    push('/help')
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <DetailsContainer>
      {!hasOrder ? (
        <div className="not-found">
          <span>
            <FiChevronLeft size={20} onClick={handlePushBack} />
          </span>
          <p>Pedido não encontrado!</p>
        </div>
      ) : (
        <>
          <div className="box">
            <span>
              <FiChevronLeft size={20} onClick={handlePushBack} />
            </span>
            <h3>Detalhes do pedido</h3>

            <div>
              <div className="status">
                <b>
                  Pedido: {state.id} - <strong>{state.status}</strong>
                </b>
                <div>
                  <Button color="secondary" onClick={handleHelp}>
                    Ajuda
                  </Button>
                </div>
              </div>

              <div className="info">
                <div>
                  <b>Valor total</b>
                  <p>{formatValue(state.price)}</p>

                  <b>Forma de pagamento</b>
                  <p>{paymentMethod}</p>

                  <b>Parcelas:</b>
                  <p>
                    {state.installments}x de{' '}
                    {formatValue(state.price / state.installments)}
                  </p>
                </div>

                <div>
                  <b>Realizado em:</b>
                  <p>{new Date(state.createdAt).toLocaleDateString()}</p>

                  <b>Previsão de entrega:</b>
                  <p>--</p>

                  <b>Endereço de entrega:</b>
                  <p>--</p>
                </div>
              </div>

              <div className="info">
                <div>
                  <b>Comprador:</b>
                  <p>{state.customer.name}</p>

                  <b>Voucher:</b>
                  <p>{state.voucher.code}</p>

                  <b>Aluno</b>
                  <p>{state.student.name}</p>
                </div>

                <div>
                  <b>CPF/CNPJ:</b>
                  <p>{cpfCnpj}</p>

                  <b>Escola:</b>
                  <p>{state.school.name}</p>

                  <div>
                    <div>
                      <b>Segmento:</b>
                      <p>{state.segment.name}</p>
                    </div>

                    <div>
                      <b>Série:</b>
                      <p>{state.serie.name}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="products">
                {state.orderAreaTypes.map((type) =>
                  type.orderAreas.map((area) =>
                    area.orderProducts.map((product) => (
                      <div key={product.codeProduct}>
                        <p>{product.name}</p>
                      </div>
                    )),
                  ),
                )}
              </div>

              <span>
                <strong>{`ATENÇÃO: `}</strong>

                <p>
                  A entrega dos materiais didáticos ocorrerá de forma
                  fracionada, obedecendo o calendário letivo da escola.
                </p>
              </span>
            </div>
          </div>

          <Button color="secondary" onClick={handleHelp}>
            Ajuda
          </Button>
        </>
      )}
    </DetailsContainer>
  )
}

export default Details
