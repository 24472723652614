import React, { useEffect } from 'react'
import { useAuth } from 'hooks/auth'
import { useHistory } from 'react-router-dom'
import { useOrder } from 'hooks/order'

import _ from 'lodash'

import Steps from 'components/Steps'
import UpdateForm from './UpdateForm'
import RegisterForm from './RegisterForm'

import { Container } from './styles'

const Identification: React.FC = () => {
  const { isAuthenticated } = useAuth()

  const { push } = useHistory()

  const { cart } = useOrder()

  useEffect(() => {
    if (_.isEmpty(cart)) push('/materials')
  }, [])

  return (
    <Container>
      <Steps current={2} />
      {isAuthenticated() ? <UpdateForm /> : <RegisterForm />}
    </Container>
  )
}

export default Identification
