/* eslint-disable no-return-assign */
import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import { Container } from './styles'

interface RadioRefs {
  checked: boolean
  value: string
}

interface RadioOptions {
  id: string
  label: string
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  options: RadioOptions[]
}

const Radio: React.FC<RadioProps> = ({ options, name, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      // path: 'value',
      ref: inputRefs.current,
      getValue(refs: RadioRefs[]) {
        const checked = refs.find((ref) => ref.checked)

        return checked ? checked.value : null
      },
      setValue(refs: RadioRefs[], value) {
        const item = refs.find((ref) => ref.value === value)

        if (item) {
          item.checked = true
        }
      },
    })
  }, [fieldName, registerField])

  return (
    <Container>
      {options?.map((option, index) => (
        <label key={option.id}>
          <input
            ref={(elRef) => elRef && (inputRefs.current[index] = elRef)}
            type="radio"
            value={option.id}
            name={fieldName}
            defaultChecked={defaultValue === option.id}
            {...rest}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </Container>
  )
}

export default Radio
