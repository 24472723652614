import React from 'react'
import { useAlert } from 'hooks/alert'

import Button from 'components/Button'

import { FiX } from 'react-icons/fi'
import { colors } from 'styles/styleguide'
import { Container } from './styles'

interface AlertProps {
  title: string
  description: string
}

const Alert: React.FC<AlertProps> = ({ title, description }) => {
  const { closeModal } = useAlert()

  return (
    <Container>
      <div className="alert-content">
        <FiX size={32} color={colors.primary} onClick={closeModal} />

        <h3>{title}</h3>
        <p>{description}</p>

        <Button onClick={closeModal}>Voltar</Button>
      </div>

      <div
        className="overlay"
        role="button"
        tabIndex={-1}
        onClick={closeModal}
        onKeyDown={closeModal}>
        {' '}
      </div>
    </Container>
  )
}

export default Alert
