import styled from 'styled-components'
import { colors, sizes } from 'styles/styleguide'

interface ContainerProps {
  hasError?: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: transparent;

  select {
    font-size: ${sizes.base};
    width: 100%;
    height: 100%;
    -webkit-appearance: none;

    border: 2px solid ${colors.grayLight};
    border-radius: 0.5rem;
    padding: 0.6rem 1.5rem 0.6rem 1rem;
    color: ${colors.grayDark};
    background: transparent;

    ${(props) => props.hasError && `border: 2px solid ${colors.error}`};

    &:disabled {
      opacity: 1;
      color: ${colors.gray};
    }

    &:invalid {
      color: ${colors.gray};
    }
  }

  option {
    color: ${colors.grayDark};

    &:disabled {
      color: ${colors.gray};
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    pointer-events: none;
    stroke: ${colors.gray};

    ${(props) => props.hasError && `stroke: ${colors.error}`};
  }

  select[hidden] {
    + svg {
      display: none;
    }
  }
`
