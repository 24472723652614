import React from 'react'
import { Switch } from 'react-router-dom'

import About from 'screens/About'
import Home from 'screens/Home'
import Help from 'screens/Help'
import Identification from 'screens/Identification'
import Materials from 'screens/Materials'
import Page404 from 'screens/Page404'
import Payment from 'screens/Payment'
import Revision from 'screens/Revision'
import Success from 'screens/Success'
import Profile from 'screens/Profile'
import Orders from 'screens/Orders'
import Details from 'screens/Orders/ListOrders/Details'

import Route from './Route'
import ScrollToTop from './ScrollToTop'

const Routes: React.FC = () => {
  return (
    <>
      <ScrollToTop />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/help" component={Help} />

        <Route path="/materials" component={Materials} />
        <Route path="/identification" component={Identification} />
        <Route path="/revision" component={Revision} isPrivate />
        <Route path="/payment" component={Payment} isPrivate />
        <Route path="/success" component={Success} isPrivate />

        <Route path="/profile" exact component={Profile} isPrivate />

        <Route path="/orders" exact component={Orders} isPrivate />
        <Route path="/orders/:id" exact component={Details} isPrivate />

        <Route component={Page404} />
      </Switch>
    </>
  )
}

export default Routes
