import React, { useMemo } from 'react'
import { CollectionProps as CollectionDataProps } from 'hooks/order'

import CardProducts from 'components/CardProducts'

import Book from 'assets/pngs/book.png'

import _ from 'lodash'
import { Container } from './styles'

interface CollectionProps {
  collection?: CollectionDataProps
  collectionTitle: string
  collectionDescription: string
}

const Collection: React.FC<CollectionProps> = ({
  collection,
  collectionTitle,
  collectionDescription,
}: CollectionProps) => {
  const typeCard = useMemo(() => {
    if (collection?.id && collection?.id >= 3) {
      return 'checkbox'
    }

    return 'radio'
  }, [collection?.id])

  return (
    <Container>
      <div>
        <div>
          <img src={Book} alt="livro" />

          <div>
            <h3>{collectionTitle}</h3>
            <p>{collectionDescription}</p>
          </div>
        </div>

        {!_.isEmpty(collection) &&
          collection?.kits.map((kit, index: number) => (
            <CardProducts
              key={Number(index)}
              areaType={collection.id}
              kit={kit}
              type={typeCard}
            />
          ))}
      </div>
    </Container>
  )
}

export default Collection
