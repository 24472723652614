/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactInputMask, { Props as ReactInputProps } from 'react-input-mask'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useField } from '@unform/core'

import { colors } from 'styles/styleguide'
import { Container } from './styles'

interface InputProps extends Omit<ReactInputProps, 'mask'> {
  name: string
  mask?: string
  isPassword?: boolean
}

const Input: React.FC<InputProps> = ({
  name,
  type = 'text',
  mask = '',
  isPassword = false,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [showPassword, setShowPassword] = useState(false)
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name)

  const handleTogglePassword = useCallback(() => {
    setShowPassword((state) => !state)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error}>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={clearError}
        mask={mask}
        maskPlaceholder=""
        name={name}
        type={showPassword ? 'text' : type}
        {...rest}
      />

      {isPassword &&
        (showPassword ? (
          <FiEyeOff
            color={colors.grayDark}
            size={24}
            onClick={handleTogglePassword}
          />
        ) : (
          <FiEye
            color={colors.grayDark}
            size={24}
            onClick={handleTogglePassword}
          />
        ))}
    </Container>
  )
}

export default Input
