import React from 'react'

import AboutImg from 'assets/pngs/about.png'

import { Container, Content, Background } from './styles'

const About: React.FC = () => {
  return (
    <Container>
      <Content>
        <h2 className="mt-2">Sobre Nós</h2>
        <p>
          A Plug Book nasceu no início de 2021, com o intuito de fornecer uma
          plataforma simples e eficiente de materiais didáticos, visando
          facilitar o dia a dia dos pais e dos profissionais de educação.
        </p>
        <p>
          Acreditamos que a tecnologia e a educação caminham juntas, e por isso
          isso nosso objetivo é que você tenha a melhor experiência conosco e
          contamos com uma equipe especializada em educação, que fornecerá os
          melhores materiais para os alunos.
        </p>
        <div className="img-container">
          <img src={AboutImg} alt="mulher com balão de fala" />
        </div>
      </Content>
      <Background />
    </Container>
  )
}

export default About
