import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { useAuth } from 'hooks/auth'

import ListOrders from './ListOrders'
import NoOrders from './NoOrders'
import { Container, Content } from './styles'

const Orders: React.FC = () => {
  const { getUserData, userData } = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  const listOrders = useMemo(() => {
    if (_.isEmpty(userData)) return []

    return userData.orders
  }, [userData])

  const getListOrders = useCallback(async () => {
    try {
      setIsLoading(true)
      await getUserData()
    } finally {
      setIsLoading(false)
    }
  }, [getUserData])

  useEffect(() => {
    getListOrders()
  }, [getListOrders])

  return (
    <Container>
      {isLoading ? (
        <div className="loading" />
      ) : (
        <Content>
          <h2>Meus pedidos</h2>
          <p>Aqui está o histórico dos seus pedidos</p>

          {listOrders.length > 0 ? (
            <ListOrders ordersQuantity={listOrders.length} />
          ) : (
            <NoOrders />
          )}
        </Content>
      )}
    </Container>
  )
}

export default Orders
