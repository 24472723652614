import styled from 'styled-components'
import { colors, sizes, responsives } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fafaf7.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background-image: url(${Bg});
  background-position: top 450px right -1100px;

  .box {
    max-width: 1067px;
    padding: 1.5rem;
    text-align: center;
    border-radius: 1.5rem;
  }

  .title {
    font-size: ${sizes.h2};
    font-weight: 700;
  }

  .subtitle {
    font-size: 14px;
    line-height: 150%;
  }

  > .main {
    position: relative;
    padding: 0;
    margin-bottom: 8.5rem;
    height: 470px;

    > .voucher {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -7.5rem;
      background: #fff;
      box-shadow: 11px 23px 24px -4px rgba(0, 0, 0, 0.25);

      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      > div {
        display: grid;
        gap: 1rem;

        > p {
          color: ${colors.primaryDark};
          font-weight: 700;
          line-height: 150%;
        }

        > form {
          display: grid;
          gap: 1rem;

          > button {
            height: 40px;
            width: 217px;
          }

          input {
            width: 426px;
            max-width: 74vw;
          }
        }
      }
    }
  }

  > .journey {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    margin-bottom: 3rem;

    > .title {
      color: ${colors.grayDark};
      font-weight: 700;
    }

    > .subtitle {
      color: ${colors.gray};
    }

    > .steps {
      max-width: 1366px;
      display: grid;
      row-gap: 3.75rem;
      column-gap: 2rem;
      margin: 3rem;
    }
  }

  > .help {
    height: 14.5rem;
    margin: 0 1rem;

    > .box {
      position: relative;
      background: ${colors.secondary};
      color: #fff;

      margin: 0 auto;
      padding: 2rem;
      display: grid;
      place-items: center;

      > .title {
        font-size: ${sizes.h2};
        margin-bottom: ${sizes.xs};
      }

      > img {
        width: 100px;
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
      }

      > a {
        > button {
          text-align: center;
          font-weight: 700;
          padding: 1rem 2rem;
          border: 1px solid #fff;
        }
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    > .main > .voucher > div > form > button {
      width: auto;
    }
  }
  @media (min-width: ${responsives.mediumDevices}) {
    > .journey {
      margin-bottom: 4rem;
      > .steps {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    > .main > .voucher {
      flex-direction: row;
      justify-content: center;
      bottom: -5rem;
      gap: 3.5rem;

      > div > form {
        grid-template-columns: 64% 32%;
        > div {
          max-width: 35vw;
        }
        > button {
          max-width: 15vw;
        }
      }
    }

    > .help {
      margin: 0 3rem;
    }
  }

  @media (min-width: ${responsives.largeDevices}) {
    background-position: top 450px right -500px;
    background-repeat: no-repeat;

    .title {
      font-size: ${sizes.h1};
    }

    .subtitle {
      font-size: ${sizes.base};
    }

    > .main > .voucher {
      > div > p {
        font-size: ${sizes.lg};
      }

      > div > form {
        input {
          max-width: 40vw;
        }
      }
    }
    > .help > .box {
      text-align: left;
      grid-template-columns: repeat(3, 1fr);

      > img {
        width: 260px;
        top: -100px;
        left: 20%;
      }
    }
  }

  /* Desktop devices */
  @media (min-width: 1281px) {
    > .journey > .steps {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`
