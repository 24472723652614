import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import api from 'services/api'

import Button from 'components/Button'

import { responsives } from 'styles/styleguide'

import BannerDesktop1 from 'assets/pngs/banner-desktop-1.png'
import BannerDesktop2 from 'assets/pngs/banner-desktop-2.png'
import BannerDesktop3 from 'assets/pngs/banner-desktop-3.png'
import BannerMobile1 from 'assets/pngs/banner-mobile-1.png'
import BannerMobile2 from 'assets/pngs/banner-mobile-2.png'
import BannerMobile3 from 'assets/pngs/banner-mobile-3.png'

import { Container } from './styles'

interface Banner {
  name: string
  fileUrlDesktop: string
  fileUrlMobile: string
}

const bannersDefault: Banner[] = [
  {
    name: 'banner 1',
    fileUrlDesktop: BannerDesktop1,
    fileUrlMobile: BannerMobile1,
  },
  {
    name: 'banner 2',
    fileUrlDesktop: BannerDesktop2,
    fileUrlMobile: BannerMobile2,
  },
  {
    name: 'banner 3',
    fileUrlDesktop: BannerDesktop3,
    fileUrlMobile: BannerMobile3,
  },
]

const Carousel: React.FC = () => {
  const [touch, setTouch] = useState({ startX: 0, endX: 0 })
  const [banners, setBanners] = useState(bannersDefault)

  const incrementBanner = useCallback(
    (i: number) => (i + 1) % banners.length,
    [banners],
  )
  const decrementBanner = useCallback(
    (i: number) => (i + banners.length - 1) % banners.length,
    [banners],
  )

  const calcCurrentBanner = (): number => {
    const carrouselWidthLeft = document.querySelector('.carousel')?.scrollLeft
    const width = window.innerWidth
    const currentBanner = Math.round(Number(carrouselWidthLeft) / width)

    return currentBanner
  }

  const scrollCarousel = (i: number) => {
    const carrousel = document.querySelector('.carousel')
    const width = window.innerWidth
    const buttonsCarousel = document.querySelectorAll('.navigation-item')

    buttonsCarousel.forEach((btn) => btn.classList.remove('active'))
    buttonsCarousel[i]?.classList.add('active')

    carrousel?.scrollTo(width * i, 0)
  }

  useEffect(() => {
    const getBanners = async () => {
      try {
        const { data } = await api.get('api/banners')

        if (!_.isEmpty(data)) setBanners(data)
      } catch (err) {
        setBanners(bannersDefault)
      }
    }

    getBanners()
  }, [])

  useEffect(() => {
    const carousel = setInterval(() => {
      const currentBanner = calcCurrentBanner()
      scrollCarousel(incrementBanner(currentBanner))
    }, 10000)

    return () => clearInterval(carousel)
  }, [banners])

  useEffect(() => {
    const [left, right] = [-30, 30]
    const distance = touch.endX - touch.startX
    const currentCarousel = calcCurrentBanner()

    if (distance >= right) {
      scrollCarousel(decrementBanner(currentCarousel))
    } else if (distance <= left) {
      scrollCarousel(incrementBanner(currentCarousel))
    }
  }, [touch.endX])

  return (
    <Container>
      <div
        className="carousel"
        tabIndex={-1}
        role="button"
        onKeyDown={(e) => e.preventDefault()}
        onTouchStart={(e) =>
          setTouch({
            ...touch,
            startX: e.changedTouches[0].clientX,
          })
        }
        onTouchEnd={(e) =>
          setTouch({
            ...touch,
            endX: e.changedTouches[0].clientX,
          })
        }>
        {banners.map(({ name, fileUrlDesktop, fileUrlMobile }) => {
          return (
            <div className="item" key={name}>
              <div className="content box">
                <div>
                  <h1 className="title">Seu material didático está aqui!</h1>
                  <p className="subtitle">
                    Adquira materias didáticos de forma rápida e simples com a
                    Plug Book.
                  </p>
                  <Link to="/about">
                    <Button type="button">Saiba Mais</Button>
                  </Link>
                </div>
              </div>
              <picture>
                <source
                  srcSet={fileUrlDesktop}
                  media={`(min-width: ${responsives.mediumDevices})`}
                />
                <img src={fileUrlMobile} alt={name} />
              </picture>
            </div>
          )
        })}

        <div className="navigation">
          {banners.map((__, i) => {
            return (
              <button
                className={`navigation-item ${i === 0 && 'active'}`}
                aria-label={i.toString()}
                key={i.toString()}
                type="button"
                onClick={() => scrollCarousel(i)}
              />
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default Carousel
