import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fffffe.png'

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 5rem);

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-position: top -840px right -580px;
  background-repeat: no-repeat;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 0 7rem;

  > h3 {
    width: 90%;
    text-align: center;
    margin-top: 2rem;
    color: ${colors.grayDark};
    font-size: ${sizes.h3};
  }

  > .content-preIdentification {
    width: 100%;
    display: flex;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem 0 4rem;

    > .content-preIdentification {
      padding: 0 1rem;
    }
  }
`

export const ActionsFooter = styled.div`
  width: 100%;
  max-width: 1067px;

  display: flex;
  justify-content: flex-end;

  margin-top: 4rem;
  padding: 0 3rem;

  > span {
    display: flex;
    align-items: center;

    > p {
      margin-right: 1rem;
      font-size: ${sizes.base};
      color: ${colors.gray};
    }

    > b {
      margin-right: 2rem;
      font-size: ${sizes.h3};
      color: ${colors.primary};
    }
  }

  > button {
    width: 194px;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    flex-direction: column;
    padding: 0 1rem;

    > span {
      justify-content: space-between;

      > p {
        margin: 0;
      }

      > b {
        margin: 0;
      }
    }

    > button {
      margin-top: 1rem;
      width: 100%;
    }
  }

  @media (max-width: ${responsives.smallDevices}) {
    > span {
      > p {
        font-size: ${sizes.sm};
      }

      > b {
        font-size: ${sizes.xl};
      }
    }
  }
`

export const Decision = styled.div`
  padding: 4rem;

  > h1 {
    color: ${colors.grayDark};
    font-size: ${sizes.xl};
    font-weight: 700;
  }

  > p {
    font-size: ${sizes.base};
    margin-top: 0.5rem;
  }

  .button_container {
    display: flex;
    flex-flow: row nowrap;

    margin-top: 12rem;

    > button {
      width: 100%;

      & + button {
        margin-left: 1rem;
        color: ${colors.secondary};
      }
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 2rem;
    text-align: center;
    
  > .button_container {
    margin-top: 9rem;

    flex-flow: column nowrap;

    > button {
      & + button {
        margin-top: 1rem;
        margin-left: 0rem;
      }
    }
  }
`
