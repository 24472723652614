/* eslint-disable no-param-reassign */
import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import { FiCheck } from 'react-icons/fi'

import { Container } from './styles'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  value,
  children,
  ...rest
}) => {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, registerField } = useField(name)

  const defaultChecked = defaultValue === value

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked
      },
      clearValue: (ref) => {
        ref.current.checked = defaultChecked
      },
      setValue: (ref, valueToSet) => {
        ref.current.checked = valueToSet
      },
    })
  }, [defaultValue, fieldName, registerField, defaultChecked])

  return (
    <Container>
      <div>
        <input
          defaultChecked={defaultChecked}
          ref={inputRef}
          value={value}
          name={name}
          type="checkbox"
          id={fieldName}
          {...rest}
        />
        <FiCheck size={12} />
      </div>

      {children}
    </Container>
  )
}

export default Checkbox
