import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from '@unform/web'
import _ from 'lodash'
import { AddressProps, useAuth } from 'hooks/auth'
import * as Yup from 'yup'

import api from 'services/api'
import Button from 'components/Button'
import Input from 'components/Form/Input'
import Modal from 'components/Modal'

import { getValidationErrors } from 'utils/validation'
import { maskTelephone } from 'utils/mask'
import { useAlert } from 'hooks/alert'
import { FormHandles } from '@unform/core'
import { Container, UserInfo, ChangePasswordContainer } from './styles'

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string()
    .required()
    .matches(/^(?=.*[a-z,A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/),
  newPasswordConfirmation: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Senhas devem ser iguais',
  ),
})

const Profile: React.FC = () => {
  const [toggleChangePasswordModal, setToggleChangePasswordModal] =
    useState(false)

  const { userData, getUserData } = useAuth()
  const { createModal } = useAlert()
  const formRef = useRef<FormHandles>(null)

  const handleToggleChangePasswordModal = useCallback(
    () => setToggleChangePasswordModal((state) => !state),
    [],
  )

  const handleChangePassword = useCallback(
    async (data) => {
      try {
        await changePasswordSchema.validate(data, { abortEarly: false })

        const payload = {
          user: userData.id,
          password: data.oldPassword,
          newPassword: data.newPassword,
        }

        await api.post('/api/change-password', payload)

        setToggleChangePasswordModal(false)
        createModal({
          title: 'Senha alterada',
          description: 'Sua senha foi alterada com sucesso!',
        })

        formRef.current?.reset()
        window.scrollTo(0, 0)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = getValidationErrors(err)

          formRef.current?.setErrors(validationErrors)
        } else if (err instanceof Error) {
          createModal({
            title: 'Erro ao mudar a senha',
            description: 'Verifique se sua senha foi válida',
          })
        }
      }
    },
    [userData],
  )

  const hasUserData = useMemo(() => !_.isEmpty(userData), [userData])
  const initialData = useMemo(() => {
    if (!userData) return {}

    let userDataAddress = {} as AddressProps

    if (userData?.addresses?.length > 0)
      userDataAddress = {
        ...userData.addresses[0],
      }

    return {
      cpf: userData?.cpf,
      email: userData?.email,
      name: userData?.name,
      birthDate:
        userData.birthDate &&
        new Date(userData.birthDate).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        }),
      phone: maskTelephone(userData?.phone),
      rg: userData?.rg,
      uf: userData?.uf,

      zipcode: userDataAddress?.postalCode,
      state: userDataAddress?.state,
      street: userDataAddress?.street,
      neighborhood: userDataAddress?.neighborhood,
      number: userDataAddress?.number,
      city: userDataAddress?.city,
    }
  }, [userData])

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Container>
      <h1>Meu Perfil</h1>

      {hasUserData ? (
        <UserInfo
          onSubmit={handleToggleChangePasswordModal}
          initialData={initialData}>
          <h3>Informações pessoais</h3>
          <div>
            <span>
              <p>CPF</p>
              <Input name="cpf" mask="999.999.999-99" disabled />
            </span>

            <span>
              <p>Nome do responsável</p>

              <Input name="name" disabled />
            </span>

            <span>
              <p>RG</p>

              <Input name="rg" mask="99.999.999-*" disabled />
            </span>

            <span>
              <p>Data de nascimento</p>

              <Input name="birthDate" disabled />
            </span>

            <span>
              <p>Orgão expedidor/UF</p>

              <Input name="uf" disabled />
            </span>

            <span>
              <p>Telefone</p>

              <Input name="phone" disabled />
            </span>
          </div>

          <h3>Endereço</h3>
          <div>
            <span>
              <p>CEP</p>
              <Input name="zipcode" mask="99999-999" disabled />
            </span>

            <span>
              <p>Bairro</p>

              <Input name="neighborhood" disabled />
            </span>

            <span>
              <p>Rua</p>

              <Input name="street" disabled />
            </span>

            <span>
              <p>Cidade</p>

              <Input name="city" disabled />
            </span>

            <span>
              <p>Número</p>

              <Input name="number" disabled />
            </span>

            <span>
              <p>Estado</p>

              <Input name="state" disabled />
            </span>
          </div>

          <h3>Cadastro</h3>
          <div>
            <span>
              <p>Email</p>
              <Input name="email" disabled />
            </span>
          </div>

          <Button>Alterar senha</Button>
        </UserInfo>
      ) : (
        <div className="loading" />
      )}

      <Modal
        visible={toggleChangePasswordModal}
        onClose={() => setToggleChangePasswordModal(false)}>
        <ChangePasswordContainer>
          <h3>Alterar senha</h3>
          <p>Preencha os campos abaixo para alterar sua senha.</p>

          <Form ref={formRef} onSubmit={handleChangePassword}>
            <span>
              <p>Senha atual</p>

              <Input
                name="oldPassword"
                placeholder="********"
                type="password"
                isPassword
              />
            </span>

            <span>
              <p>Nova senha</p>

              <Input
                name="newPassword"
                placeholder="********"
                type="password"
                isPassword
              />
            </span>
            <p>
              *A senha deve conter 8 ou mais caracteres com uma combinação de
              letras, números e símbolos
            </p>

            <span>
              <p>Confirme a nova senha</p>

              <Input
                name="newPasswordConfirmation"
                placeholder="********"
                type="password"
                isPassword
              />
            </span>

            <Button type="submit" color="secondary">
              Salvar
            </Button>
          </Form>
        </ChangePasswordContainer>
      </Modal>
    </Container>
  )
}

export default Profile
