import React from 'react'
import { useHistory } from 'react-router-dom'

import Steps from 'components/Steps'
import Button from 'components/Button'

import womanWithCart from 'assets/pngs/woman-with-cart.png'

import { colors } from 'styles/styleguide'
import { Container, Content } from './styles'

const Success: React.FC = () => {
  const { push, location } = useHistory()

  const handleFinish = () => {
    push('/orders')
  }

  const handleBoleto = () => {
    alert('baixar boleto') // eslint-disable-line no-alert
  }

  const getButtons = () => {
    return (
      <div className="buttons">
        {location.state === 'credit_card' ? (
          <Button color="secondary" onClick={handleFinish}>
            Finalizar
          </Button>
        ) : (
          <>
            <Button onClick={handleFinish} style={{ color: colors.secondary }}>
              Finalizar
            </Button>
            <Button color="secondary" onClick={handleBoleto}>
              Baixar Boleto
            </Button>
          </>
        )}
      </div>
    )
  }

  return (
    <Container>
      <Content>
        <Steps current={5} />

        <div>
          <h2>Sucesso!</h2>
          <p>
            Agora é só aguardar o pagamento e aguardar para retirar o material
            didático.
          </p>

          <img src={womanWithCart} alt="" />

          <p>A nota fiscal será encaminhada no e-mail informado.</p>

          {getButtons()}
        </div>
        {getButtons()}
      </Content>
    </Container>
  )
}

export default Success
