import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import api from 'services/api'

import CustomHTMLModal from 'components/CustomHTMLModal'

export interface Term {
  id: number
  title: string
  content: string
  isActive: boolean
  isRequired: boolean
}

interface TermContextData {
  handleToggleModalTerm: (term: Term) => void
  termToId: (title: string) => Term
  terms: Term[]
}

const TermContext = createContext<TermContextData>({} as TermContextData)

export const TermProvider: React.FC = ({ children }) => {
  const [terms, setTerms] = useState([] as Term[])

  const emptyTerm = {
    id: 0,
    title: '',
    content: '',
    isActive: false,
    isRequired: false,
  }

  const [selectedTerm, setSelectedTerm] = useState(emptyTerm)
  const [toggleModalTerm, setToggleModalTerm] = useState(false)

  const handleToggleModalTerm = useCallback((term: Term) => {
    setSelectedTerm(term)
    setToggleModalTerm((state) => !state)
  }, [])

  const termToId = useCallback(
    (title: string) => terms.filter((term) => term.title === title)[0],
    [terms],
  )

  useEffect(() => {
    async function loadTerms() {
      try {
        const { data } = await api.get(`/api/terms`)

        if (data) setTerms(data)
      } catch (err) {
        setTerms([] as Term[])
      }
    }

    loadTerms()
  }, [])
  return (
    <TermContext.Provider
      value={{
        handleToggleModalTerm,
        termToId,
        terms,
      }}>
      {children}

      <CustomHTMLModal
        visible={toggleModalTerm}
        onClose={() => handleToggleModalTerm(emptyTerm)}
        customHTML={selectedTerm.content}
      />
    </TermContext.Provider>
  )
}

export const useTerm = (): TermContextData => {
  const context = useContext(TermContext)

  if (!context) throw new Error('useTerm must be within TermProvider')

  return context
}
