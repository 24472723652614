import React from 'react'
import { NavLink } from 'react-router-dom'
import { NavbarElements } from './styles'

interface NavbarProps {
  isActive?: string
}

const Navbar: React.FC<NavbarProps> = () => {
  return (
    <NavbarElements>
      <NavLink exact to="/" activeClassName="active">
        Início
      </NavLink>
      <NavLink exact to="/about" activeClassName="active">
        Sobre nós
      </NavLink>
      <NavLink exact to="/help" activeClassName="active">
        AJUDA
      </NavLink>
    </NavbarElements>
  )
}

export default Navbar
