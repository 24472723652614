import React, { useEffect, useState } from 'react'
import api from 'services/api'

import WomanWithBalloon from 'assets/pngs/woman-with-balloon.png'

import ItemAccordion from 'components/ItemAccordion'

import { Container, Content, Background } from './styles'

export interface FaqCategoryProps {
  id: number
  question: string
  answer: string
}

export interface FaqProps {
  id: number
  name: string
  faqs: FaqCategoryProps[]
}

const Help: React.FC = () => {
  const [open, setOpen] = useState('')
  const [faq, setFaq] = useState<FaqProps[]>([] as FaqProps[])

  useEffect(() => {
    const item = document.getElementById(open)
    item?.scrollIntoView()
  }, [open])

  useEffect(() => {
    async function getFaq() {
      try {
        const { data, status } = await api.get('/api/faq_categories')

        if (status === 200) {
          setFaq(data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getFaq()
  }, [])

  const itemsAccordion = faq.map(({ id, name, faqs }: FaqProps) => {
    return (
      <ItemAccordion
        id={name}
        key={id}
        name={name}
        faqs={faqs}
        open={open === name}
        setOpen={() => (name === open ? setOpen('') : setOpen(name))}
      />
    )
  })

  return (
    <Container>
      <Content>
        <h2>Dúvidas?</h2>
        <div className="accordion">{itemsAccordion}</div>

        <div className="needs-help">
          <img src={WomanWithBalloon} alt="mulher com balão de fala" />
          <div>
            <p className="title">Ainda precisa de ajuda?</p>
            <div className="email">
              <a href="mailto:faleconosco@plugbook.com.br?subject=Ajuda">
                faleconosco@plugbook.com.br
              </a>
            </div>
            <p className="tip">
              <span>Dica</span>: Conte com detalhes sua situação, assim
              concluímos mais rápido seu atendimento. Retornaremos em breve!
            </p>
          </div>
        </div>
      </Content>
      <Background />
    </Container>
  )
}

export default Help
