import styled from 'styled-components'
import { colors, responsives } from 'styles/styleguide'

import backgroundImg from 'assets/pngs/footer-bg.png'
import backgroundMobileImg from 'assets/pngs/footer-mobile-bg.png'

export const Container = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 2rem 8rem;
  margin-top: auto;

  background: ${colors.primaryDark};

  > button {
    position: absolute;
    top: -10%;
    right: 5%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    width: 70px;
    height: 70px;
  }

  > .LogoContainer {
    z-index: 2;
    color: #fff;

    display: flex;
    flex-flow: column;

    > img {
      max-width: 187px;
    }

    > span {
      margin-top: 3rem;

      flex-flow: row wrap;
      font-size: 0.8rem;
      max-width: 75%;
    }
  }

  > .LinksContainer {
    z-index: 2;
    flex-grow: 2;
    justify-content: space-evenly;

    display: grid;
    grid-template-columns: repeat(3, auto);

    > div {
      display: flex;
      flex-flow: column;
      padding: 2rem;

      > h1 {
        color: ${colors.secondaryLight};
        font-size: 1rem;
        text-transform: uppercase;
      }

      > button {
        background: none;
        border: none;
        text-align: left;
      }

      > a,
      > button {
        font-size: 1rem;
        line-height: 21px;
        color: #fff;
        text-decoration: none;
        margin-top: 1rem;
        font-weight: 400;

        & + a {
          /* margin-left: 3rem; */
        }
      }

      > a:hover,
      > button:hover {
        border-bottom: 2px solid #2ec3e5;
        margin-bottom: -2px;
        cursor: pointer;

        transition: color 0.2s linear;
        -o-transition: color 0.2s linear;
        -moz-transition: color 0.2s linear;
        -webkit-transition: color 0.2s linear;
      }
    }
  }

  @media (max-width: ${responsives.smallDevices}) {
    padding: 1;

    > div {
      flex-grow: 0;
      flex-flow: column;

      > a + a,
      > button + button {
        margin-left: 0rem;
      }
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 1rem;

    display: flex;
    flex-flow: column-reverse;
    justify-content: center;

    > button {
      top: -5%;
    }

    > .LogoContainer {
      justify-content: center;
      align-items: center;
      margin-top: 4rem;

      > span {
        margin-top: 1rem;
        text-align: center;
      }
    }

    > .LinksContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;

      width: 100%;
      padding: 1rem;

      > div {
        padding: 0rem;
        > h1 {
          margin-top: 3rem;
          /* margin-bottom: 1rem; */
        }

        > h1 + a,
        > h1 + button {
          padding-top: 1rem;
          border-top: 0.5px solid ${colors.secondaryLight};
        }

        > a + a,
        > button + button {
          padding-top: 1rem;
          border-top: 0.5px solid ${colors.secondaryLight};
        }

        > a:hover,
        > button:hover {
          border-bottom: 0px;
          margin-bottom: 0px;
          cursor: pointer;
        }
      }
    }
  }
`
export const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  width: 100%;
  height: 100%;

  @media (max-width: ${responsives.largeDevices}) {
    background-image: url(${backgroundMobileImg});
  }
`
