import styled from 'styled-components'
import { colors, responsives, sizes } from 'styles/styleguide'

import Bg from 'assets/pngs/bg-fffffe.png'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  background-color: #fafaf7;
  background-image: url(${Bg});
  background-position: top -840px right -580px;
  background-repeat: no-repeat;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 0 7rem;

  > .box {
    max-width: 1067px;
    width: 90%;

    display: flex;
    flex-flow: column;

    padding: 2rem 4rem;
    margin-top: 2rem;

    background: #fff;

    border: 1px solid rgba(207, 209, 255, 1);
    border-radius: 4px;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.25);

    > button {
      width: 100%;
      width: 330px;
      margin: 1rem auto;
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    > .box {
      padding: 1rem;
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 1rem 0 4rem;

    > .box {
      width: 100%;

      margin: 0;

      background: transparent;

      border: 0;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);

      > button {
        width: 100%;
      }
    }
  }
`

export const ItemsContent = styled.div`
  width: 100%;

  padding: 0 3rem;
  margin: 2rem 0;

  > div {
    position: relative;
    max-width: 1067px;
    padding-top: 1.5rem;
    margin: 0 auto;

    > h2 {
      font-size: ${sizes.xl};
      color: ${colors.grayDark};
      margin-bottom: 2rem;
    }

    > button {
      position: absolute;
      top: 1rem;
      right: 2rem;

      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f4f5ff;
      border: none;

      color: transparent;

      > svg {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);

        color: ${colors.primary};
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 0 1rem;
    margin: 0;
  }

  @media (max-width: ${responsives.mediumDevices}) {
    padding: 0;
    > div > button {
      top: 100%;
      right: 0;

      width: 100%;
      height: 40px;
      border-radius: 0 0 8px 8px;

      background: #8387f7;
      color: #fff;

      > svg {
        color: transparent;
      }
    }
  }
`

export const Item = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;

  padding: 1rem 3.5rem;
  margin-bottom: 10px;

  border-radius: 8px;
  border: 2px solid ${colors.primaryLight};
  box-shadow: 0px 6px 10px -1px rgba(0, 0, 0, 0.15);

  background: #fffffe;

  > div {
    margin-right: auto;

    b {
      color: ${colors.grayDark};
      margin-right: 0.5rem;
    }

    div {
      display: flex;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      p {
        font-weight: 400;
        color: ${colors.gray};

        & + p {
          margin-left: 1rem;
        }
      }

      span {
        font-weight: 400;
        color: ${colors.gray};
        display: none;
      }
    }
  }

  > span {
    display: flex;
    align-items: center;
    gap: 1rem;

    font-size: ${sizes.sm};
    color: ${colors.gray};

    b {
      font-size: ${sizes.lg};
      font-weight: 700;
      color: ${colors.primaryDark};
    }
  }

  @media (max-width: ${responsives.largeDevices}) {
    padding: 1rem 1.5rem;

    > div > div {
      flex-direction: column;

      p + p {
        margin-left: 0;
        margin-top: 0.2rem;
      }
    }

    > span {
      flex-direction: column;
      gap: 0.3rem;
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    column-gap: 1rem;

    font-size: ${sizes.sm};
    align-items: start;

    margin-bottom: 0;

    border-radius: 8px 8px 0 0;
    border-bottom: none;

    & + div {
      border-radius: 0;
      border-bottom: none;
    }

    > div {
      b {
        font-size: ${sizes.base};
      }

      div > span {
        margin-top: 0.5rem;
        display: block;
      }
    }

    > span {
      b {
        font-size: ${sizes.lg};
      }

      span {
        display: none;
      }
    }

    > .unity {
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
`

export const FreightContent = styled.div`
  width: 100%;

  padding: 0 3rem;

  > div {
    position: relative;
    max-width: 1067px;
    margin: 0 auto;

    > h2 {
      font-size: ${sizes.xl};
      color: ${colors.grayDark};
      margin-bottom: 2rem;
    }

    > span {
      width: 100%;
      max-width: 1067px;
      height: min-content;

      display: flex;
      justify-content: center;

      border-radius: 8px;
      background-color: ${colors.primaryLight};
      color: ${colors.primaryDark};
      font-weight: 500;

      padding: 1rem;
      margin-bottom: 2rem;
    }
  }
  @media (max-width: ${responsives.mediumDevices}) {
    padding: 0;
    margin-top: 5rem;
  }
`

export const Freight = styled.div`
  position: relative;
  width: 100%;
  text-align: left;

  border-radius: 8px;
  overflow: hidden;

  margin-bottom: 2rem;

  .label {
    display: flex;
    justify-content: space-between;

    padding: 1rem;

    background: ${colors.primaryLight};

    p {
      color: ${colors.grayDark};
      font-weight: 700;
    }

    b {
      font-size: ${sizes.lg};
      font-weight: 700;
      color: ${colors.primaryDark};
    }
  }

  .panel {
    border: 2px solid ${colors.primaryLight};
    border-top: 0;
    border-bottom: 0;

    padding: 1rem;
    padding-right: 5rem;

    p {
      color: ${colors.gray};
      font-weight: 500;
    }

    > div {
      display: flex;
      gap: 1.5rem;

      & + div {
        margin-top: 1.5rem;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      b {
        color: ${colors.grayDark};
        font-weight: 700;
      }
    }
  }

  @media (max-width: ${responsives.mediumDevices}) {
    .panel {
      padding-right: 2.5rem;
      div {
        flex-direction: column;
      }
    }
  }
`
